<template>
  <BaseModal
    name="pixel-fb"
    size="lg"
    :title="$t('seller.products.pixelfb.text_653')"
    @shown="openModal"
    @hidden="reset"
  >
    <!-- Loading -->
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-pixel" v-else>
      <b-form novalidate v-show="step == 1">
        <!-- Imagem -->
        <img :src="iconeType" class="mb-4" :class="{'ga4': this.type_pixel.toLowerCase() === 'ga4'}" />
        <!-- Id Pixel -->
        <b-form-group label="ID Pixel" label-for="pixel_id">
          <b-form-input
            id="pixel_id"
            name="pixel_id"
            type="text"
            autocomplete="off"
            v-model="pixel.pixel_id"
            :placeholder="cPlaceholderPixel"
            v-validate="'required'"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('pixel_id')">
            {{ $t('seller.products.pixelfb.text_654') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!-- Label Conversion -->
        <b-form-group
          v-if="type_pixel === 'GOOGLEADWORDS'"
          :label="$t('seller.products.pixelfb.text_655')"
          label-for="label_conversion"
          class="mt-4"
        >
          <b-form-input
            id="label_conversion"
            name="label_conversion"
            type="text"
            :placeholder="$t('seller.products.pixelfb.text_655')"
            autocomplete="off"
            v-model="pixel.label"
          ></b-form-input>
        </b-form-group>
        <!-- Events you like to receive -->
        <b-form-group
          class="mt-4"
          id="events_pixel"
          :label="$t('seller.products.pixelfb.text_656')"
          label-for="events_pixel"
          data-anima="top"
          v-show="pixel.pixel_id"
        >
          <b-form-checkbox
            class="mt-3"
            name="events_pixel"
            v-model="pixel.view"
            size="lg"
            switch
          >
            <p class="info-checkbox">
             {{ $t('seller.products.pixelfb.text_657') }}
              <span class="descricao-checkbox">
                {{ $t('seller.products.pixelfb.text_658') }}
              </span>
            </p>
          </b-form-checkbox>

          <b-form-checkbox
            class="mt-3"
            name="events_pixel"
            v-model="pixel.conversion"
            size="lg"
            switch
          >
            <p class="info-checkbox">
              {{ $t('seller.products.pixelfb.text_659') }}
              <span class="descricao-checkbox">
                {{ $t('seller.products.pixelfb.text_660') }}
              </span>
            </p>
          </b-form-checkbox>
        </b-form-group>
        <!-- Advenced Configs -->
        <div v-if="pixel.pixel_id && pixel.conversion">
          <span
            data-anima="top"
            class="collapse-item collapse-arrow"
            v-b-toggle.collapse-pixel
            :class="{ ativo: collapse }"
            @click="collapse = !collapse"
          >
            {{ $t('seller.products.pixelfb.text_661') }}
            <img src="@/assets/img/icons/arrow-fill.svg" />
          </span>

          <b-collapse id="collapse-pixel" data-anima="top" class="mt-3">
            <b-form-group
              class="mb-3"
              :label="$t('seller.products.pixelfb.text_662')"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                class="mt-1"
                id="radio-group-1"
                v-model="pixel.confirmed_only"
                :options="options_pagamento"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
                stacked
              ></b-form-radio-group>
            </b-form-group>

            <div>
              <b-form-checkbox
                class="mt-3"
                name="events_pixel"
                v-model="pixel.web"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.products.pixelfb.text_663') }}
                  <span class="descricao-checkbox">
                    {{ $t('seller.products.pixelfb.text_664') }}
                  </span>
                </p>
              </b-form-checkbox>

              <b-form-checkbox
                v-show="type_pixel === 'FACEBOOK'"
                class="mt-3"
                name="events_pixel"
                v-model="pixel.api"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.products.pixelfb.text_665') }}
                  <span class="descricao-checkbox">
                    {{ $t('seller.products.pixelfb.text_666') }}
                  </span>
                </p>
              </b-form-checkbox>

              <b-form-checkbox
                v-show="type_pixel === 'FACEBOOK'"
                class="mt-3"
                name="events_pixel"
                v-model="pixel.amount"
                size="lg"
                switch
              >
                <p class="info-checkbox">
                  {{ $t('seller.products.pixelfb.text_699') }}
                  <span class="descricao-checkbox">{{ $t('seller.products.pixelfb.text_700') }}</span>
                </p>
              </b-form-checkbox>

              <b-form-group
                :label="$t('seller.products.pixelfb.text_667')"
                label-for="token"
                v-if="pixel.api"
                data-anima="top"
                class="mt-3"
              >
                <b-form-input
                  id="token"
                  name="token"
                  type="text"
                  autocomplete="off"
                  v-model="pixel.token"
                  :placeholder="$t('seller.products.pixelfb.text_667')"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('token')">
                  {{ $t('seller.products.pixelfb.text_668') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-collapse>
        </div>
      </b-form>
      <b-form v-if="step == 2">
        <div data-anima="bottom">
          <b-form-group
            :label="$t('seller.products.pixelfb.text_669')"
            v-slot="{ ariaDescribedby }"
          >
            <p class="mini-info">
              {{ $t('seller.products.pixelfb.text_670') }} {{ type_pixel }}, {{ $t('seller.products.pixelfb.text_671') }} <span>{{ $t('seller.products.pixelfb.text_672') }}</span>
            </p>
            <b-form-radio-group
              class="mt-3 mr-3"
              id="radio-group-2"
              v-model="has_domain"
              :options="options_domain"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <div v-if="has_domain == 'true'" class="mt-3" data-anima="top">
          <b-form-group :label="$t('seller.products.pixelfb.text_673')">
            <ul class="steps">
              <li>
                <span>01.</span>
                <div>
                  <p>
                    {{ $t('seller.products.pixelfb.text_674') }}
                    <b>{{ $t('seller.products.pixelfb.text_675') }} {{ type_pixel }}.</b>
                  </p>
                  <span class="mini-info">
                    {{ $t('seller.products.pixelfb.text_676') }}
                  </span>
                  <div class="mt-2">
                    <b-form-input
                      id="domain"
                      name="domain"
                      type="text"
                      autocomplete="off"
                      v-model="pixel.domain.domain"
                      placeholder="Ex: seudominio.com.br"
                      v-validate="'required'"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('domain')">
                      {{ $t('seller.products.pixelfb.text_677') }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </li>
              <li>
                <span>02.</span>
                <div>
                  <p>{{ $t('seller.products.pixelfb.text_678') }}</p>
                  <span class="mini-info">
                    {{ $t('seller.products.pixelfb.text_679') }} {{ type_pixel }} {{ $t('seller.products.pixelfb.text_680') }}
                  </span>
                  <ul class="instrucoes">
                    <li>
                      <p>
                        a. {{ $t('seller.products.pixelfb.text_681') }}
                      </p>
                    </li>
                    <li>
                      <p>
                        b. {{ $t('seller.products.pixelfb.text_682') }}
                        <b v-if="pixel && pixel.domain && pixel.domain.domain"
                          >greenn.{{ pixel.domain.domain }}</b
                        >
                        <b v-else>greenn.meudominio.com.br</b> {{ $t('seller.products.pixelfb.text_683') }}
                        <b>pixel.greenn.com.br</b>
                      </p>
                    </li>
                    <li><p>c. {{ $t('seller.products.pixelfb.text_684') }}</p></li>
                    <li>
                      <p>d. {{ $t('seller.products.pixelfb.text_685') }}</p>
                      <span class="mini-info">
                        {{ $t('seller.products.pixelfb.text_686') }}
                      </span>
                      <div class="status" :class="{ ativada: pixel.dns }">
                        CNAME
                        {{ pixel.dns ? $t('seller.products.pixelfb.text_688') : $t('seller.products.pixelfb.text_689') }}
                      </div>
                      <div class="status" :class="{ ativada: pixel.ssl }">
                        {{ $t('seller.products.pixelfb.text_687') }}
                        {{ pixel.ssl ? $t('seller.products.pixelfb.text_688') : $t('seller.products.pixelfb.text_689') }}
                      </div>
                    </li>
                    <li>
                      <p>
                        e. {{ $t('seller.products.pixelfb.text_690') }}
                        {{ type_pixel }}.
                      </p>
                      <span class="mini-info">
                        {{ $t('seller.products.pixelfb.text_691') }}
                        {{ type_pixel }} {{ $t('seller.products.pixelfb.text_692') }}</span>
                      <b-form-group
                        class="mt-4 mb-3"
                        id="optimization_pixel"
                        label=""
                        label-for="optimization_pixel"
                        data-anima="top"
                      >
                        <b-form-checkbox
                          class="mt-3"
                          name="optimization_pixel"
                          v-model="pixel.optimization"
                          size="lg"
                          switch
                          :disabled="!pixel.dns && !pixel.ssl"
                        >
                          <p class="info-checkbox">{{ $t('seller.products.pixelfb.text_693') }}</p>
                        </b-form-checkbox>
                      </b-form-group>
                    </li>
                  </ul>
                  <span class="mini-info">
                    {{ $t('seller.products.pixelfb.text_694') }}
                  </span>
                </div>
              </li>
            </ul>
          </b-form-group>
        </div>
      </b-form>
    </section>

    <template v-slot:footer>
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-3"
        @click="back"
      >
        {{ step == 1 ? $t('seller.products.pixelfb.text_701') : $t('seller.products.pixelfb.text_702') }}
      </BaseButton>
      <div v-if="pixel.pixel_id" data-anima="right">
        <BaseButton variant="black" @click="next">
          {{ step == 1 ? $t('seller.products.pixelfb.text_703') : $t('seller.products.pixelfb.text_704') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import ConversionPixelService from "@/services/resources/ConversionPixelService";
const serviceConversionPixel = ConversionPixelService.build();

export default {
  props: {
    product_id: {
      type: Number,
      default: -1,
    },
    type_pixel: {
      type: String,
      default: "FACEBOOK",
    },
    pixel_edit: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      collapse: false,
      step: 1,
      placeholderPixel: {
        FACEBOOK: "Ex: 1234567890",
        GOOGLEADWORDS: "Ex: AW-0000000000",
        GOOGLETAGMANAGER: "Ex: GTM-XXXXXXX",
        GOOGLEANALYTICS: "Ex: UA-000000-00",
        GA4: "Ex: G-XXXXXXXX",
        TIKTOK: "Ex: 1234567890",
      },
      pixel: {
        pixel_id: "",
        product_id: -1,
        type: "",
        confirmed_only: 0,
        web: true,
        api: false,
        amount: false,
        token: "",
        domain: {
          domain: "",
        },
        view: true,
        conversion: true,
      },
      pixel_request: {},
      options_pagamento: [
        { text: this.$t('seller.products.pixelfb.text_695'), value: 0 },
        { text: this.$t('seller.products.pixelfb.text_696'), value: 1 },
      ],
      has_domain: null,
      options_domain: [
        { text: this.$t('seller.products.pixelfb.text_705'), value: "true" },
        { text: this.$t('seller.products.pixelfb.text_706'), value: "false" },
      ],
    };
  },

  computed: {
    iconeType() {
      if (this.type_pixel) {
        return require(`@/assets/img/${this.type_pixel.toLowerCase()}_icone.png`);
      } else return false;
    },
    cPlaceholderPixel() {
      return this.placeholderPixel[this.type_pixel] || "";
    },
  },
  methods: {
    back() {
      if (this.step == 2) {
        this.step = 1;
      } else {
        this.$bvModal.hide("pixel-fb");
      }
    },
    next() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.step == 1) {
            this.step = 2;
          } else {
            this.formatObjectPixel();
          }
        }
      });
    },
    formatObjectPixel() {
      this.pixel_request = {
        pixel_id: this.pixel.pixel_id,
        product_id: this.product_id,
        type: this.pixel.type,
        web: this.pixel.web ? 1 : 0,
        api: this.pixel.api ? 1 : 0,
        confirmed_only: this.pixel.confirmed_only ? 1 : 0,
        token: this.pixel.token,
        domain: this.pixel.domain.domain,
        view: this.pixel.view ? 1 : 0,
        conversion: this.pixel.conversion ? 1 : 0,
        optimization: this.pixel.optimization ? 1 : 0,
      };

      if (this.type_pixel === "GOOGLEADWORDS" && this.pixel.label) {
        this.pixel_request.label = this.pixel.label;
      }

      if (this.type_pixel != "FACEBOOK") {
        this.pixel_request.api = 0;
        this.pixel_request.token = "";
      }

      if (this.type_pixel == "FACEBOOK") {
        this.pixel_request.amount = this.pixel.amount ? 1 : 0;
      }

      this.onSubmit();
    },
    atualizaPixel() {
      this.loading = true;
      let data = {
        id: this.pixel.id,
        ...this.pixel_request,
      };
      serviceConversionPixel
        .update(data)
        .then(() => {
          this.$bvToast.toast(this.$t('seller.products.pixelfb.text_697'), {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit("close", true);
          this.$bvModal.hide("pixel-fb");
          this.reset();
        })
        .catch((err) =>  console.error(err))
        .finally(() => { this.loading = false })
    },
    onSubmit() {
      if (this.pixel_edit.edit) {
        this.atualizaPixel();
      } else {
        this.loading = true;
        serviceConversionPixel
          .create(this.pixel_request)
          .then(() => {
            this.$bvToast.toast(this.$t('seller.products.pixelfb.text_698'), {
              title: "Pixel",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$emit("close", true);
            this.$bvModal.hide("pixel-fb");
            this.reset();
          })
          .catch((err) => console.error(err))
          .finally(() => { this.loading = false })
      }
    },
    openModal() {
      this.loading = true;

      if (this.pixel_edit.edit) {
        this.pixel = {
          id: this.pixel_edit.item.id,
          pixel_id: this.pixel_edit.item.pixel_id,
          product_id: this.product_id,
          type: this.pixel_edit.item.type,
          label: this.pixel_edit.item.label,
          dns: this.pixel_edit.item.dns,
          ssl: this.pixel_edit.item.ssl,
          confirmed_only: this.pixel_edit.item.confirmed_only ? 1 : 0,
          web: this.pixel_edit.item.web ? true : false,
          api: this.pixel_edit.item.api ? true : false,
          amount: this.pixel_edit.item.amount ? true : false,
          token: this.pixel_edit.item.token,
          domain: this.pixel_edit.item.domain
            ? this.pixel_edit.item.domain
            : [],
          view: this.pixel_edit.item.view ? true : false,
          conversion: this.pixel_edit.item.conversion ? true : false,
          optimization: this.pixel_edit.item.optimization ? true : false,
        };

        if (this.pixel.domain && this.pixel.domain.domain) {
          this.has_domain = "true";
        } else {
          this.has_domain = "false";
        }
      } else {
        this.reset();
      }
      this.loading = false;
    },
    reset() {
      this.pixel = {
        pixel_id: "",
        product_id: -1,
        type: this.type_pixel,
        confirmed_only: 0,
        web: true,
        api: false,
        token: "",
        domain: {
          domain: "",
        },
        view: true,
        conversion: true,
      };

      this.has_domain = null;
      this.collapse = false;
      this.step = 1;
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#pixel_id {
  width: 300px;
}
#events_pixel {
  max-width: 480px;
}
.info-checkbox {
  cursor: pointer;
}
.info-checkbox span {
  font-size: 12px;
}
.descricao-checkbox {
  margin-left: 0 !important;
}
.collapse-item {
  margin-top: 30px;
  font-size: 13px;
  color: #2133d2;
  padding: 15px 20px;
  border: 1px solid #ededf0;
  border-radius: 5px;
}
// collapse
.collapse-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.collapse-arrow.ativo {
  margin-bottom: 15px;
  color: #333;
  border-bottom-color: transparent;
}
.collapse-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}
.mini-info {
  color: #81858e !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.mini-info span {
  font-weight: 600;
  color: #81858e;
}
.steps {
  margin: 10px 0;
  /* max-width: 80%; */
}
.steps li {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.steps li + li {
  margin-top: 15px;
}
.steps li span {
  font-weight: 600;
  font-size: 14px;
  color: #2133d2;
}
.steps p {
  font-size: 14px;
}

// instrucoes
.instrucoes {
  margin: 10px 0;
}
.instrucoes li {
  display: block;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 5px;
  max-width: 79%;
}
@media screen and (max-width: 768px) {
  .instrucoes li {
    max-width: 100%;
  }
}
.instrucoes li p {
  font-size: 13px;
  line-height: 1.5;
}
.instrucoes li + li {
  margin-top: 15px;
}
.status {
  margin-top: 10px;
  display: inline-block;
  background: #eee;
  color: #000;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.status.ativada {
  background: #00948820;
  color: #009488;
}
.status + .status {
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .status {
    display: block;
  }
  .status + .status {
    margin: 0;
    margin-top: 10px;
  }
}
.info-checkbox {
  display: inline;
}
#token {
  max-width: 500px;
}

.ga4 {
  height: 60px;
}
</style>
