<template>
  <section>
    <custom-header
      :title="$t('views.seller.edit_product.text_1062')"
      :subtitle="$t('views.seller.edit_product.advanced.subtitle')"
      @action="openModal('product-co-sellers')"
      :show-button="true"
      :button-text="$t('views.seller.edit_product.text_1063')"
    />

    <div data-anima="left">
      <section class="coProduces__list-container">
        <section class="list-box">
          <div class="flex">
            <div class="list-avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect
                  width="40"
                  height="40"
                  rx="5"
                  fill="#002092"
                  fill-opacity="0.1"
                />
                <path
                  d="M27 27.625V26C27 25.138 26.6576 24.3114 26.0481 23.7019C25.4386 23.0924 24.612 22.75 23.75 22.75H17.25C16.388 22.75 15.5614 23.0924 14.9519 23.7019C14.3424 24.3114 14 25.138 14 26V27.625M23.75 16.25C23.75 18.0449 22.2949 19.5 20.5 19.5C18.7051 19.5 17.25 18.0449 17.25 16.25C17.25 14.4551 18.7051 13 20.5 13C22.2949 13 23.75 14.4551 23.75 16.25Z"
                  stroke="#002092"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="user-data">
              <p class="list-title">
                {{ product.seller.name }}
              </p>
              <span class="list-item">
                {{ product.seller.email }}
              </span>
            </div>
          </div>
          <div class="content">
            <p class="list-title">
              {{ $t("views.seller.edit_product.coproduces.type") }}
            </p>
            <span class="list-item">
              {{ $t("views.seller.edit_product.coproduces.type_producer") }}
            </span>
          </div>
          <div>
            <p class="list-title">
              {{ $t("views.seller.edit_product.coproduces.comission") }}
            </p>
            <span class="list-item"> {{ 100 - percentage_total }}% </span>
          </div>
          <div class="last">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
            >
              <path
                d="M10 1L12.781 6.634L19 7.543L14.5 11.926L15.562 18.118L10 15.193L4.438 18.118L5.5 11.926L1 7.543L7.219 6.634L10 1Z"
                stroke="#002092"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </section>

        <!-- coSellers List -->
        <section
          class="list-box"
          v-for="(coSeller, index) in coSellers"
          :key="coSeller.id"
        >
          <div class="flex">
            <div class="list-avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="17"
                viewBox="0 0 20 17"
                fill="none"
              >
                <path
                  d="M13.2727 15.7273V14.0909C13.2727 13.2229 12.9279 12.3905 12.3142 11.7767C11.7004 11.163 10.868 10.8182 10 10.8182H4.27273C3.40475 10.8182 2.57232 11.163 1.95856 11.7767C1.3448 12.3905 1 13.2229 1 14.0909V15.7273M16.5455 5.09091V10M19 7.54545H14.0909M10.4091 4.27273C10.4091 6.0802 8.94384 7.54545 7.13636 7.54545C5.32889 7.54545 3.86364 6.0802 3.86364 4.27273C3.86364 2.46525 5.32889 1 7.13636 1C8.94384 1 10.4091 2.46525 10.4091 4.27273Z"
                  stroke="#333333"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="user-data">
              <p class="list-title">
                {{ coSeller.user.name }}
              </p>
              <span class="list-item">
                {{ coSeller.user.email }}
              </span>
            </div>
          </div>
          <div class="content">
            <p class="list-title">
              {{ $t("views.seller.edit_product.coproduces.type") }}
            </p>
            <span class="list-item">
              {{ getTypeCoSeller(coSeller.type) }}
            </span>
          </div>
          <div>
            <p class="list-title">
              {{ $t("views.seller.edit_product.coproduces.comission") }}
            </p>
            <span class="list-item"> {{ coSeller.percentage }}% </span>
          </div>
          <div
            class="last action"
            @click="removeCoProduces(index, coSeller.id, coSeller.user.name)"
            :id="'btn-remover-' + coSeller.id"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
            >
              <path
                d="M1 4.55556H2.77778M2.77778 4.55556H17M2.77778 4.55556V17C2.77778 17.4715 2.96508 17.9237 3.29848 18.2571C3.63187 18.5905 4.08406 18.7778 4.55556 18.7778H13.4444C13.9159 18.7778 14.3681 18.5905 14.7015 18.2571C15.0349 17.9237 15.2222 17.4715 15.2222 17V4.55556M5.44444 4.55556V2.77778C5.44444 2.30628 5.63175 1.8541 5.96514 1.5207C6.29854 1.1873 6.75073 1 7.22222 1H10.7778C11.2493 1 11.7015 1.1873 12.0349 1.5207C12.3683 1.8541 12.5556 2.30628 12.5556 2.77778V4.55556M7.22222 9V14.3333M10.7778 9V14.3333"
                stroke="#81858E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </section>
      </section>
    </div>
    <co-seller @added="isAdded" :product="product" :product_id="product" />
  </section>
</template>
<script>
import Vue from "vue";
import CoSeller from "@/components/Seller/Products/modals/coSeller";
import CustomHeader from "./shared/CustomHeader.vue";

import CoSellerService from "@/services/resources/CoSellerService";
const serviceCoSeller = CoSellerService.build();

import { mapGetters, mapActions } from "vuex";
import Permissions from "@/utils/permissions.js";
import { EventBus } from "@/main.js";

export default {
  components: {
    CoSeller,
    CustomHeader,
  },
  computed: {
    ...mapGetters(["getProfile", "coSellers", "product", "productId"]),
    /**
     * @return {boolean} - If user is heaven or greenn;
     */
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  data() {
    return {
      currentlyTab: 0,
      percentage_total: 0,
      optionsCoProdutor: [
        { value: null, text: this.$t("views.seller.edit_product.text_2344") },
      ],
      Permissions,
    };
  },
  methods: {
    ...mapActions(["fetchProduct"]),
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    isAdded(data) {
      this.coSellers = [];
      for (let i = 0; i < data.length; i++) {
        this.coSellers.unshift({
          id: data[i].id,
          user_id: data[i].user_id.id,
          percentage: parseFloat(String(data[i].percentage).replace(",", ".")),
          type: data[i].type,
          user: {
            name: data[i].user.name,
            email: data[i].user_id.email,
          },
        });
      }
      this.sumPercentage();
    },
    sumPercentage() {
      this.percentage_total = this.coSellers.reduce((total, item) => {
        return total + item.percentage;
      }, 0);
    },
    removeCoProduces(index, id, name) {
      Vue.swal({
        title: this.$t("seller.products.listagem_coseller.text_513"),
        text: `${this.$t(
          "seller.products.listagem_coseller.text_514"
        )} ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t(
          "seller.products.listagem_coseller.text_513"
        ),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await serviceCoSeller
            .destroy({ id })
            .then((response) => {
              if (response.success) {
                this.coSellers.splice(index, 1);

                this.sumPercentage();

                this.$bvToast.toast(
                  this.$t("seller.products.listagem_coseller.text_515"),
                  {
                    title: this.$t(
                      "seller.products.listagem_coseller.text_1946"
                    ),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_coseller.text_516"),
                  {
                    title: this.$t(
                      "seller.products.listagem_coseller.text_1946"
                    ),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.$emit("deleted", name);
            })
            .catch((err) => console.error(err));
        }
      });
    },
    getTypeCoSeller(type) {
      let type_format = "";
      switch (type) {
        case "ALL":
          type_format = this.$t("seller.products.co_seller.text_331");
          break;
        case "AFFILIATE":
          type_format = this.$t("seller.products.co_seller.text_332");
          break;
        case "NOT_AFFILIATE":
          type_format = this.$t("seller.products.co_seller.text_333");
          break;
        case "MANAGER_AFFILIATE":
          type_format = this.$t("seller.products.co_seller.text_334");
          break;
      }
      return type_format;
    },
  },
  mounted() {
    this.sumPercentage();

    EventBus.$on("atualizaPorcentagem", () => {
      this.sumPercentage();
    });
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}

.flex-wrap {
  align-content: center;
  flex-wrap: wrap;
}

.coProduces {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      @extend .default-font;
      color: $secondary-color;
      font-size: 16px;
    }

    .subtitle {
      @extend .default-font;
      color: #81858e;
      font-size: 14px;
      font-weight: 400;
    }

    .co-producer-button {
      margin-left: 15px;
    }
  }

  &__list-container {
    margin-top: 1.88rem;

    .list-box {
      display: flex;
      justify-content: space-between;
      border: 1px solid #e5e5e5;
      padding: 1.56rem;
      border-radius: 0.625rem;
      margin-bottom: 0.94rem;
    }

    .user-data {
      width: 200px;
    }

    .list-title {
      font-size: 0.9375rem;
      font-weight: bold;
      color: #333;
    }

    .list-avatar {
      margin-right: 20px;
      background: #f7f7f7;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      height: 40px;
      width: 40px;
      border-radius: 5px;
    }

    .list-item {
      @extend .default-font;
      font-size: 0.875rem;
      color: #81858e;
      display: flex;
      flex-wrap: wrap;
      font-weight: 400;
    }

    .content {
      width: 150px;
    }

    .last {
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    .action {
      cursor: pointer;
    }
  }
}
</style>