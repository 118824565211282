<template>
  <BaseModal
    name="modal-integracoes_gdigital"
    size="lg"
    :title="$t('seller.products.gdigital.text_347')"
    @shown="openModal"
    @hidden="clearFields"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <!-- gDigital -->
      <h4 class="title-inter">• G Digital</h4>
      <b-form data-vv-scope="gdigital">
        <div
          data-anima="top"
          class="codigo-verifica"
          v-if="!integration || !token"
        >
          <div v-if="novo_email == false" data-anima="top">
            <p>
              {{ $t("seller.products.gdigital.text_348") }}
              {{ email || $store.state.user.user.email }}
              {{ $t("seller.products.gdigital.text_349") }}
            </p>
            <b-form-group
              :label="$t('seller.products.gdigital.text_1897')"
              label-for="code_verification"
            >
              <b-form-input
                id="code_verification"
                name="code_verification"
                v-model="code"
                :placeholder="$t('seller.products.gdigital.text_351')"
                autocomplete="code_verification"
                v-validate="'required'"
                :class="{ heaven: isHeaven }"
              />
              <b-form-invalid-feedback
                :state="!errors.has('gdigital.code_verification')"
              >
                {{ $t("seller.products.gdigital.text_350") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div v-if="novo_email" data-anima="top">
            <p v-if="g_email">
              {{ $t("seller.products.gdigital.text_352") }}
            </p>
            <p v-else>
              {{ $t("seller.products.gdigital.text_353") }}
              <br />
              {{ $t("seller.products.gdigital.text_354") }}
              {{ getEmail }},
              {{ $t("seller.products.gdigital.text_355") }} <br /><br />
              {{ $t("seller.products.gdigital.text_356") }}
            </p>
            <!-- Email -->
            <b-form-group
              :label="$t('seller.products.gdigital.text_357')"
              label-for="novo_email"
            >
              <b-form-input
                id="novo_email"
                name="novo_email"
                v-model="email"
                :placeholder="$t('seller.products.gdigital.text_1898')"
                autocomplete="novo_email"
                v-validate="'required|email'"
                :class="{ heaven: isHeaven }"
              />
              <b-form-invalid-feedback
                :state="!errors.has('gdigital.novo_email')"
              >
                {{ $t("seller.products.gdigital.text_358") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <!-- Usar email do Greenn -->
            <a
              href="#"
              class="sem-codigo"
              @click.prevent="emailGdigital('greenn')"
              >{{
                novo_email
                  ? $t("seller.products.gdigital.text_359")
                  : $t("seller.products.gdigital.text_360")
              }}</a
            >
            <!-- Não recebeu o código? -->
            <a
              href="#"
              class="sem-codigo"
              v-if="!novo_email"
              @click.prevent="reenviarCodigoGdigital"
              >{{ $t("seller.products.gdigital.text_361") }}</a
            >
            <a href="#" class="sem-codigo" v-else></a>
          </div>
        </div>

        <b-row v-if="integration && token">
          <b-col cols="12" sm="6" data-anima="top" v-if="!loading">
            <b-form-group
              :label="$t('seller.products.gdigital.text_362')"
              label-for="blogs"
            >
              <b-form-select
                id="blogs"
                name="blogs"
                v-model="blog"
                :options="blogs_gdigital"
                :placeholder="$t('seller.products.gdigital.text_1899')"
                :class="{ heaven: isHeaven }"
                @change="changeBlogs"
              >
              </b-form-select>
              <b-form-invalid-feedback :state="!errors.has('gdigital.blogs')">
                {{ $t("seller.products.gdigital.text_363") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="select_blog && !loading" data-anima="top">
            <h6 class="titulo-acao">
              {{ $t("seller.products.gdigital.text_364") }}
            </h6>
            <section class="layout-forms">
              <b-form-group
                :label="$t('seller.products.gdigital.text_1900')"
                label-for="check-sales"
              >
                <b-form-checkbox
                  switch
                  size="sm"
                  name="check-sales"
                  class="check-box mb-2"
                  v-for="op in options_sales"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Assinatura" label-for="check">
                <b-form-checkbox
                  size="sm"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Checkout" label-for="check">
                <b-form-checkbox
                  size="sm"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </section>
          </b-col>
          <b-col cols="12" v-if="select_blog && !loading" data-anima="top">
            <b-form-group
              :label="$t('seller.products.gdigital.text_365')"
              label-for="forms_gdigital"
            >
              <multiselect
                v-model="form_id"
                :options="forms"
                :value="form_id"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('seller.products.gdigital.text_365')"
                label="text"
                track-by="text"
                selectLabel=""
                deselectLabel=""
                selectedLabel="✔️"
                id="multi_select_forms_gdigital"
                name="forms_gdigital"
                v-validate="'required'"
                :class="{ heaven: isHeaven }"
              >
              </multiselect>
              <b-form-invalid-feedback
                :state="!errors.has('gdigital.forms_gdigital')"
              >
                {{ $t("seller.products.gdigital.text_366") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-row>
      </b-form>
    </section>

    <template v-slot:footer="{ cancel }" data-anima="top">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t("seller.products.gdigital.text_1901") }}</BaseButton
      >

      <BaseButton
        v-if="integration && token"
        variant="primary"
        :disabled="loading"
        :class="{ heaven: isHeaven }"
        @click="initCreateCallBack"
      >
        {{ $t("seller.products.gdigital.text_1902") }}
      </BaseButton>

      <BaseButton
        v-if="(!integration && !novo_email) || (integration && !token && !novo_email)"
        variant="black"
        :disabled="loading"
        @click="validaCodigo"
        >{{ $t("seller.products.gdigital.text_367") }}</BaseButton
      >
      <BaseButton
        v-if="(!integration && novo_email) || (integration && !token  && novo_email)"
        variant="black"
        :disabled="loading"
        @click="enviar_codigo"
        >{{ $t("seller.products.gdigital.text_1903") }}</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import { api_gdigital } from "@/services/Gdigital.js";
import Multiselect from "vue-multiselect";
import Cookies from 'js-cookie';

import CallbackService from "@/services/resources/CallbackService";

const serviceCallback = CallbackService.build();

export default {
  name: "GDigital",
  components: {
    Multiselect,
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    gdigital: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      submit: false,
      integration: false,
      token: "",
      novo_email: false,
      email: "",
      blog: "",
      code: "",
      trigger: [],
      form_id: -1,
      select_blog: false,
      options_sales: [
        {
          value: "salePaid",
          text: this.$t("seller.products.options_sales.text_751"),
        },
        {
          value: "saleRefused",
          text: this.$t("seller.products.options_sales.text_752"),
        },
        {
          value: "saleRefunded",
          text: this.$t("seller.products.options_sales.text_753"),
        },
        {
          value: "saleChargedback",
          text: this.$t("seller.products.options_sales.text_754"),
        },
        {
          value: "saleUnpaid",
          text: this.$t("seller.products.options_sales.text_755"),
        },
        {
          value: "saleWaitingPayment",
          text: this.$t("seller.products.options_sales.text_756"),
        },
      ],
      options_sub: [
        {
          value: "contractUnpaid",
          text: this.$t("seller.products.options_sub.text_773"),
        },
        {
          value: "contractPaid",
          text: this.$t("seller.products.options_sub.text_774"),
        },
        {
          value: "contractTrialing",
          text: this.$t("seller.products.options_sub.text_775"),
        },
        {
          value: "contractPendingPayment",
          text: this.$t("seller.products.options_sub.text_776"),
        },
        {
          value: "contractEnded",
          text: this.$t("seller.products.options_sub.text_777"),
        },
        {
          value: "contractCanceled",
          text: this.$t("seller.products.options_sub.text_778"),
        },
      ],
      options_checkout: [
        {
          value: "checkoutAbandoned",
          text: this.$t("seller.products.options_checkout.text_779"),
        },
      ],
      forms: [],
      blogs_gdigital: [],
      g_email: true,
      new_g: false
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    getEmail(){
      if(this.$store.getters.setPanel) {
        return  Cookies.get('setPanelEmail')
      }
      
      return this.$store.state.user.user.email
    }
  },
  methods: {
    // checkbox dos eventos
    triggerChanged(v) {
      if (this.trigger.includes(v)) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },

    // limpa os campos / reseta
    clearFields() {
      this.loading = false;
      this.submit = false;
      this.integration = false;
      this.token = "";
      this.novo_email = false;
      this.email = "";
      this.blog = "";
      this.code = "";
      this.trigger = [];
      this.form_id = -1;
      this.select_blog = false;
      this.options_sales = [
        {
          value: "salePaid",
          text: this.$t("seller.products.options_sales.text_751"),
        },
        {
          value: "saleRefused",
          text: this.$t("seller.products.options_sales.text_752"),
        },
        {
          value: "saleRefunded",
          text: this.$t("seller.products.options_sales.text_753"),
        },
        {
          value: "saleChargedback",
          text: this.$t("seller.products.options_sales.text_754"),
        },
        {
          value: "saleUnpaid",
          text: this.$t("seller.products.options_sales.text_755"),
        },
        {
          value: "saleWaitingPayment",
          text: this.$t("seller.products.options_sales.text_756"),
        },
      ];
      this.options_sub = [
        {
          value: "contractUnpaid",
          text: this.$t("seller.products.options_sub.text_773"),
        },
        {
          value: "contractPaid",
          text: this.$t("seller.products.options_sub.text_774"),
        },
        {
          value: "contractTrialing",
          text: this.$t("seller.products.options_sub.text_775"),
        },
        {
          value: "contractPendingPayment",
          text: this.$t("seller.products.options_sub.text_776"),
        },
        {
          value: "contractEnded",
          text: this.$t("seller.products.options_sub.text_777"),
        },
        {
          value: "contractCanceled",
          text: this.$t("seller.products.options_sub.text_778"),
        },
      ];
      this.forms = [];
      this.blogs_gdigital = [];
    },

    // iniciando a criação das callbacks
    async initCreateCallBack() {
      await this.createCallBack()
        .finally(() => {
          setTimeout(() => {
            this.$bvModal.hide("modal-integracoes_gdigital");
            this.$emit("close");
            this.clearFields();
          }, 1000);
        })
    },

    // adicionar uma nova callback
    async createCallBack() {
      return new Promise(async(resolve) => {
        this.$validator.validateAll("gdigital").then(async(result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            const newForm = this.form_id.value;
            if (newForm) {
              let data = {
                product_id: this.product.id,
                id_form: newForm,
                type: "gdigital",
                callback: "cadastrarGdigital",
                new_g: this.new_g,
                auth: this.auth
              };

              if (this.trigger.length) {
                this.trigger.forEach((event) => {
                  data.trigger = "";
                  setTimeout(async () => {
                    data.trigger = event;
                    await this.postCallback(data, event);
                    resolve(true);
                  }, 500);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(
                  this.$t("seller.products.gdigital.text_1904"),
                  {
                    title: "G Digital",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                resolve(false);
              }
            } else {
              this.loading = false;
              this.$bvToast.toast(
                this.$t("seller.products.gdigital.text_368"),
                {
                  title: "G Digital",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },

    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${this.convertTrigger(event)}] ${this.$t(
              "seller.products.gdigital.text_1905"
            )}`,
            {
              title: "G Digital",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          EventBus.$emit("reloadGdigitalIntegration");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // puxa todos os formularios da g digital
    getFormsGdigital() {
      this.loading = true;
      this.forms = [];
      api_gdigital
        .get(`/forms?auth=${this.auth}&new_g=${this.new_g}`)
        .then((r) => {
          [...r.data].forEach((item) => {
            this.forms.push({
              value: item.ID,
              text: item.post_title,
            });
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        });
    },

    // evento change par atualizar o auth (forms)
    changeBlogs() {
      this.select_blog = true;
      this.form_id = null;
      const array = this.blog.toString().split("|");
      this.getAuthGdigital(array[0], array[1], array[2]);
    },

    // atualizar o auth (forms) da g digital
    getAuthGdigital(id_blog, auth_blog, new_g) {
      this.loading = true;
      const panel_id = +id_blog;
      const auth = auth_blog;
      const email = this.email || this.$store.state.user.user.email;
      const token = this.token;
      this.new_g = new_g;


      api_gdigital
        .get(
          `/auth?panel_id=${panel_id}&auth=${auth}&email_gdigital=${email}&token=${token}&new_g=${new_g}`
        )
        .then((r) => {
          this.integration = true;
          this.auth = auth;
          this.getFormsGdigital();
          this.$emit("codeGdigitalChecked");

          this.submit = false;
        })
        .catch((error) => {
          console.error(error);
          this.submit = false;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // puxa todos os blogs do usuario na g digital
    getBlogsGdigital() {
      this.loading = true;
      let email = this.email || this.$store.state.user.user.email;
      if(this.$store.getters.setPanel) email = Cookies.get('setPanelEmail')

      this.blogs_gdigital = [];
      api_gdigital
        .get(`/blogs?email=${email}`)
        .then(res => {
          [...res.data].forEach((item) => {
            this.blogs_gdigital.push({
              value: `${item.panel_id}|${item.auth}|${item.new_g}`,
              text: `${item.name} - G ${item.new_g == true ? '2.0' : '1.0'}`,
            })
          })

          if (!this.forms[0]) {
            this.getFormsGdigital();
          }

        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },

    // valida o codigo de verificação que foi enviado no email
    validaCodigo() {
      this.$validator.validateAll("gdigital").then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          api_gdigital
            .get(`/token/${this.code}`)
            .then((r) => {
              this.token = r.data;
              this.integration = true;
              this.$bvToast.toast(
                this.$t("seller.products.gdigital.text_370"),
                {
                  title: "G Digital",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );

              // começar ajustar daqui Novo fluxp
              this.getBlogsGdigital();
            })
            .catch((error) => {
              this.submit = false;
              console.error(error);
            }).finally(() => {
              this.loading = false;
            })
        }
      });
    },

    // envia o codigo de verificação com um novo email
    async enviar_codigo() {
      await this.reenviarCodigoGdigital();
    },

    // reenvia o codigo de verificação
    async reenviarCodigoGdigital() {
      await this.emailGdigital();
    },

    // envia email com o codigo de verificação
    async emailGdigital(origem) {
      this.loading = true;
  
      let email = (!origem ? this.email : null) || this.$store.state.user.user.email;
      if(this.$store.getters.setPanel){
        email = Cookies.get('setPanelEmail')
      } 

      await api_gdigital
        .get(`/email?email=${email}`)
        .then((r) => {
          this.g_email = true;
          this.novo_email = false;
          this.$bvToast.toast(this.$t("seller.products.gdigital.text_370"), {
            title: "G Digital",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.g_email = false;
          this.novo_email = true;
          this.$bvToast.toast(this.$t("seller.products.gdigital.text_371"), {
            title: "G Digital",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // abrindo modal e setando valores
    async openModal() {
      this.loading = true;
      if (this.gdigital.integration) {
        this.integration = this.gdigital.integration;
        this.token = this.gdigital.token;
        this.email = this.gdigital.email;
        this.blogs_gdigital = this.gdigital.blogs_gdigital;
        this.loading = false;
      } else {
        await this.emailGdigital();
      }
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid":
          return this.$t("seller.products.options_sales.text_751");
        case "saleRefused":
          return this.$t("seller.products.options_sales.text_752");
        case "saleRefunded":
          return this.$t("seller.products.options_sales.text_753");
        case "BOLETO":
          return this.$t("seller.products.options_saleswaiting.text_758");
        case "PIX":
          return this.$t("seller.products.options_saleswaiting.text_759");
        case "saleChargedback":
          return this.$t("seller.products.options_sales.text_754");
        case "saleUnpaid":
          return this.$t("seller.products.options_sales.text_755");
        case "saleWaitingPayment":
          return this.$t("seller.products.options_sales.text_756");

        //  assinaturas
        case "contractUnpaid":
          return this.$t("seller.products.options_sub.text_773");
        case "contractPaid":
          return this.$t("seller.products.options_sub.text_774");
        case "contractTrialing":
          return this.$t("seller.products.options_sub.text_775");
        case "contractPendingPayment":
          return this.$t("seller.products.options_sub.text_776");
        case "contractEnded":
          return this.$t("seller.products.options_sub.text_777");

        //  checkout
        case "checkoutAbandoned":
          return this.$t("seller.products.options_checkout.text_779");

        //  venda recusada
        case "INVALID_CREDIT_CARD":
          return this.$t("seller.products.options_salesrefused.text_761");
        case "INSUFFICIENT_FUNDS":
          return this.$t("seller.products.options_salesrefused.text_762");
        case "INVALID_PAYMENT_TYPE":
          return this.$t("seller.products.options_salesrefused.text_763");
        case "INVALID_DATA":
          return this.$t("seller.products.options_salesrefused.text_764");
        case "INVALID_CLIENT_DATA":
          return this.$t("seller.products.options_salesrefused.text_765");
        case "INVALID_CVV":
          return this.$t("seller.products.options_salesrefused.text_766");
        case "BANK":
          return this.$t("seller.products.options_salesrefused.text_767");
        case "CREDIT_CARD_OPERATOR":
          return this.$t("seller.products.options_salesrefused.text_768");
        case "INVALID_INSTALLMENTS":
          return this.$t("seller.products.options_salesrefused.text_769");
        case "CURRENCY_NOT_SUPPORTED":
          return this.$t("seller.products.options_salesrefused.text_770");
        case "SUSPECTED_FRAUD":
          return this.$t("seller.products.options_salesrefused.text_771");
        case "GENERIC":
          return this.$t("seller.products.options_salesrefused.text_772");
        default:
          return trigger;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}
.layout-forms {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 100px;
  padding: 20px 0;
}
// .form-group + .form-group {
//   margin-top: 15px;
// }
// /* // codigo de verificação g digital */
// .codigo-verifica {
//   max-width: 60%;
// }
// @media screen and (max-width: 768px) {
//   .codigo-verifica {
//     max-width: 100%;
//   }
// }
// .codigo-verifica p {
//   font-size: 14px;
//   font-weight: normal;
//   color: #81858e;
//   line-height: 1.5;
//   margin-bottom: 20px;
// }
// .sem-codigo {
//   font-size: 14px;
//   color: #333;
// }
// .sem-codigo:hover {
//   font-weight: normal;
// }
// .titulo-acao {
//   font-weight: 600;
//   font-size: 14px;
//   margin-bottom: 10px;
// }

// .check-box .info-checkbox {
//   font-size: 13px;
//   font-weight: 500;
//   margin-left: 5px;
//   cursor: pointer;
//   max-width: 250px;
//   margin-top: 15px !important;
// }

// .form-control.heaven:hover {
//   border-color: $base-color-heaven !important;
// }
</style>