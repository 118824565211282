<template>
  <list-item
    v-if="!loading"
    title="LeadLovers"
    :list="list"
    @delete="removeLeadLovers"
  />
  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "@/main.js";
// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  name: "ListagemLeadLovers",
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  methods: {
    async fetchCallbacks() {
      this.loading = true;
      let data = {
        product_id: this.id_product || this.$route.params.id_product,
        type: "leadlovers",
      };
      await serviceCallback
        .search(data)
        .then((r) => {
          this.list = [...r]
            .reverse()
            .filter((item) => item.type === "leadlovers")
            .map((item) => ({
              name: `${item.id} - ${this.convertTrigger(item.trigger)} | ${
                item.title
              }`,
              description: this.convertTrigger(item.sub_trigger),
              id: item.id,
              icon: "document.svg",
              actions: ["delete"],
            }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async removeLeadLovers(payload) {
      const { index, name, id } = payload;
      Vue.swal({
        title: "Remover Evento",
        text: `Deseja continuar com a remoção do #${id} - ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loading = true;

          let data = {
            id: id,
          };
          await serviceCallback
            .destroy(data)
            .then(async(response) => {
              if (response == 1) {
                this.$bvToast.toast("Evento removido com sucesso!", {
                  title: "LeadLovers",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.fetchCallbacks();
              } else {
                this.$bvToast.toast("Houve um erro ao remover o Evento", {
                  title: "LeadLovers",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid":
          return this.$t("seller.products.options_sales.text_751");
        case "saleRefused":
          return this.$t("seller.products.options_sales.text_752");
        case "saleRefunded":
          return this.$t("seller.products.options_sales.text_753");
        case "BOLETO":
          return this.$t("seller.products.options_saleswaiting.text_758");
        case "PIX":
          return this.$t("seller.products.options_saleswaiting.text_759");
        case "saleChargedback":
          return this.$t("seller.products.options_sales.text_754");
        case "saleUnpaid":
          return this.$t("seller.products.options_sales.text_755");
        case "saleWaitingPayment":
          return this.$t("seller.products.options_sales.text_756");

        //  assinaturas
        case "contractUnpaid":
          return this.$t("seller.products.options_sub.text_773");
        case "contractPaid":
          return this.$t("seller.products.options_sub.text_774");
        case "contractTrialing":
          return this.$t("seller.products.options_sub.text_775");
        case "contractPendingPayment":
          return this.$t("seller.products.options_sub.text_776");
        case "contractEnded":
          return this.$t("seller.products.options_sub.text_777");

        //  checkout
        case "checkoutAbandoned":
          return this.$t("seller.products.options_checkout.text_779");

        //  venda recusada
        case "INVALID_CREDIT_CARD":
          return this.$t("seller.products.options_salesrefused.text_761");
        case "INSUFFICIENT_FUNDS":
          return this.$t("seller.products.options_salesrefused.text_762");
        case "INVALID_PAYMENT_TYPE":
          return this.$t("seller.products.options_salesrefused.text_763");
        case "INVALID_DATA":
          return this.$t("seller.products.options_salesrefused.text_764");
        case "INVALID_CLIENT_DATA":
          return this.$t("seller.products.options_salesrefused.text_765");
        case "INVALID_CVV":
          return this.$t("seller.products.options_salesrefused.text_766");
        case "BANK":
          return this.$t("seller.products.options_salesrefused.text_767");
        case "CREDIT_CARD_OPERATOR":
          return this.$t("seller.products.options_salesrefused.text_768");
        case "INVALID_INSTALLMENTS":
          return this.$t("seller.products.options_salesrefused.text_769");
        case "CURRENCY_NOT_SUPPORTED":
          return this.$t("seller.products.options_salesrefused.text_770");
        case "SUSPECTED_FRAUD":
          return this.$t("seller.products.options_salesrefused.text_771");
        case "GENERIC":
          return this.$t("seller.products.options_salesrefused.text_772");
        default:
          return trigger;
      }
    },
  },
  async mounted() {
    await this.fetchCallbacks();
     EventBus.$on("reloadLeadLoversIntegration", async () => {
      await this.fetchCallbacks();
    });
  },
};
</script>
