<template lang="">
    <BaseModal
    name="update-webhook-modal"
    size="lg"
    :title="title"
    @shown="open"
  >
    <b-form-input v-model="url"></b-form-input>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="link-info"
        class="mr-4"
        @click="$bvModal.hide('update-webhook-modal')"
      >
        {{ $t('seller.products.webhook.text_750') }}
      </BaseButton>
      <BaseButton variant="black" @click="$emit('updated', { ...webhook.obj, url })">
        {{ $t('update') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
export default {
  props: {
    webhook: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: "",
      title: "",
    };
  },
  methods: {
    open() {
      this.url = this.webhook.url;
      this.title = `#${this.webhook.id} - ${this.webhook.name}`;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>