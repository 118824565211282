<template>
    <section data-anima="top">
        <section v-if="product.product_type_id != 4" class="Advanced__Stock">
            <FormItem :title="$t('views.seller.edit_product.pysical_product.stock')"
                :sub-title="$t('views.seller.edit_product.pysical_product.stock_description')">
                <b-form-checkbox size="lg" v-model="has_stock" switch />
            </FormItem>

            <FormItem :title="$t('views.seller.edit_product.pysical_product.stock_qtd')" data-anime="top" v-if="has_stock"
                :sub-title="$t('views.seller.edit_product.pysical_product.stock_qtd_description')">

                <b-form-group label-for="stock" data-anima="top">
                <b-form-input class="product__input-size" id="stock" placeholder="30" name="stock" v-model="stock"
                    v-validate="'required|numeric|min_value:1'" type="number"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('stock')">
                    {{ $t("views.seller.edit_product.text_1018") }}
                </b-form-invalid-feedback>
                </b-form-group>
            </FormItem>
            <br>
        </section>
    
        <custom-header :title="$t('views.seller.edit_product.advanced.title')"
            :subtitle="$t('views.seller.edit_product.advanced.subtitle_general')" :show-button="true"
            :button-text="$t('views.seller.edit_product.tracking.btn-text')" @action="newThankYouPage" />

            <section class="Advanced__thankYouPage">
            <section>
                <b-form-group :label="$t('views.seller.edit_product.advanced.urls.default')" label-for="url_page_boleto">
                    <b-form-input name="url_page_boleto" class="default-input" v-model="thank_you_page" type="text"
                        placeholder="https://obrigado-cartao.com" :class="{ heaven: isHeaven }"></b-form-input>
                </b-form-group>
            </section>

            <section>
                <section class="custom-container" data-anima="bottom" v-for="(_, index) in custom_thank_you_pages"
                    :key="index">
                    <section class="custom-box left">
                        <b-form-group :label="$t('views.seller.edit_product.text_2249')" :label-for="`type_page-${index}`">
                            <b-form-select v-model="custom_thank_you_pages[index].type" :name="`type_page-${index}`"
                                :options="type_pages" />
                        </b-form-group>
                    </section>
                    <section class="custom-box right">
                        <div class="remove-page" @click.prevent="removeThankYouPage(index)">
                            <a href="#"> x </a>
                        </div>
                        <b-form-group
                            :label="'URL ' + $t('views.seller.edit_product.thanks_pages.' + custom_thank_you_pages[index].type)"
                            :label-for="`url_page-${index}`">
                            <b-form-input :name="`url_page-${index}`" v-model="custom_thank_you_pages[index].url"
                                type="text" placeholder="http://www.exemplo.com"></b-form-input>
                        </b-form-group>
                    </section>
                </section>
            </section>

            <section class="Advanced__Checkout">
                <custom-header class="header" :title="$t('views.seller.edit_product.custom_checkout.title')"
                    :subtitle="$t('views.seller.edit_product.custom_checkout.subtitle')" :show-button="false" />

                <FormItem :title="$t('views.seller.edit_product.custom_checkout.address')"
                    v-if="(this.product.product_type_id !== 4 && product.format !== 'PHYSICALPRODUCT')"
                    :sub-title="$t('views.seller.edit_product.custom_checkout.address_desk')">
                    <b-form-checkbox v-model="is_checkout_address" size="lg" switch />
                </FormItem>

                <FormItem class="advanced-top" :title="$t('views.seller.edit_product.custom_checkout.upsell_title')"
                    :sub-title="$t('views.seller.edit_product.custom_checkout.upsell_desk')">
                    <b-form-checkbox v-model="smart_sale" size="lg" switch />
                </FormItem>

                <FormItem class="advanced-top" :title="$t('views.seller.edit_product.text_1090')" v-if="smart_sale && !smart_sale_dynamic_installments"
                    :sub-title="$t('views.seller.edit_product.text_1091')"
                    :disable-border="true"
                    >

                    <b-form-group label-for="smart_sale_installments">
                        <b-form-select name="smart_sale_installments" v-model="smart_sale_installments"
                            :options="option_fixed_installments" v-validate="'required'"
                            :class="{ heaven: isHeaven }" />

                        <b-form-invalid-feedback :state="!errors.has('smart_sale_installments')">
                            {{ $t("views.seller.edit_product.text_1092") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </FormItem>

                <FormItem class="advanced-top" :title="$t('views.seller.edit_product.text_2252')" v-if="smart_sale"
                    :sub-title="$t('views.seller.edit_product.text_1094')"
                    :disable-border="true"
                    >

                    <b-form-group label-for="smart_sale_dynamic_installments">

                        <b-form-checkbox v-model="smart_sale_dynamic_installments" size="lg" switch />

                        <b-form-invalid-feedback :state="!errors.has('smart_sale_dynamic_installments')">
                            {{ $t("views.seller.edit_product.text_1092") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </FormItem>

                <FormItem class="advanced-top" :title="$t('views.seller.edit_product.custom_checkout.coproduce_title')"
                    :sub-title="$t('views.seller.edit_product.custom_checkout.coproduce_desc')">
                    <b-form-select class="select-producer" v-model="co_seller_display_id" :options="optionsCoProdutor"
                        :class="{ heaven: isHeaven }" />
                </FormItem>
            </section>

            <section class="Advanced__FastAcess">
                <custom-header class="header" :title="$t('views.seller.edit_product.custom_checkout.fast_access.title')"
                    :subtitle="$t('views.seller.edit_product.custom_checkout.fast_access.subtitle')" :show-button="false" />

                <FormItem 
                  :title="$t('views.seller.edit_product.custom_checkout.fast_access.checkout_title')" 
                  :sub-title="$t('views.seller.edit_product.custom_checkout.fast_access.checkout_desc')"
                  v-if="!$store.getters.setPanel"
                >
                    <router-link :to="`/checkouts?p_id=${productId}`">
                        <button class="outline-button">
                            {{ $t('views.seller.edit_product.custom_checkout.fast_access.checkout_button') }}
                            <svg style="margin-left:10px" xmlns="http://www.w3.org/2000/svg" width="11" height="10"
                                viewBox="0 0 11 10" fill="none">
                                <path
                                    d="M1.24264 9.24264L9.72792 0.757359M9.72792 0.757359H1.24264M9.72792 0.757359V9.24264"
                                    stroke="#004392" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </router-link>
                </FormItem>

                <FormItem class="advanced-top" :title="$t('views.seller.edit_product.custom_checkout.fast_access.bump_title')"
                    v-if="product.type == 'TRANSACTION' && product.format !== 'PHYSICALPRODUCT'"
                    :sub-title="$t('views.seller.edit_product.custom_checkout.fast_access.bump_desc')">
                    <router-link
                        :to="`/links/?orderbump=true&p_id=${productId}&p_name=${product.name}&p_type=${product.type}`">
                        <button class="outline-button">
                            {{ $t('views.seller.edit_product.custom_checkout.fast_access.bump_button') }}
                            <svg style="margin-left:10px" xmlns="http://www.w3.org/2000/svg" width="11" height="10"
                                viewBox="0 0 11 10" fill="none">
                                <path
                                    d="M1.24264 9.24264L9.72792 0.757359M9.72792 0.757359H1.24264M9.72792 0.757359V9.24264"
                                    stroke="#004392" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </router-link>
                </FormItem>

                <FormItem 
                    class="advanced-top"  
                    :title="$t('views.seller.edit_product.custom_checkout.fast_access.upsell_title')"
                    :sub-title="$t('views.seller.edit_product.custom_checkout.fast_access.upsell_desc')"
                    v-if="!$store.getters.setPanel"
                >
                    <router-link :to="`/checkouts/?upsell=true&p_id=${productId}`">
                        <button class="outline-button">
                            {{ $t('views.seller.edit_product.custom_checkout.fast_access.upsell_button') }}
                            <svg style="margin-left:10px" xmlns="http://www.w3.org/2000/svg" width="11" height="10"
                                viewBox="0 0 11 10" fill="none">
                                <path
                                    d="M1.24264 9.24264L9.72792 0.757359M9.72792 0.757359H1.24264M9.72792 0.757359V9.24264"
                                    stroke="#004392" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </router-link>
                </FormItem>
            </section>

        </section>
    </section>
</template>

<script>
// Components
import CustomHeader from "../shared/CustomHeader.vue";
import FormItem from "../shared/FormItem.vue";

// Core
import { mapGetters } from "vuex";
import CustomThankYouPages from "@/services/resources/CustomThankYouPages";
const serviceCustomThankYouPages = CustomThankYouPages.build();

// Mixins
import Money from "@/mixins/money";
import Permissions from "@/utils/permissions.js";
import { EventBus } from "@/main.js";

export default {
    components: {
        CustomHeader,
        FormItem,
    },
    watch: {
        custom_thank_you_pages: function () {
            this.teleportData();
        },
    },
    computed: {
        ...mapGetters([
            "getProfile",
            "product",
            "productId",
            "isLoading",
            "coSellers",
            'allowSmartSale',
            'allowCustomPeriod',
        ]),
        product() {
            return this.$store.getters['product'];
        },
        isHeaven() {
            return this.$store.getters["getIsHeaven"];
        },
        has_stock: {
            get() {
                return !!this.$store.state.product.product.has_stock;
            },
            set(has_stock) {
                this.$store.commit("setProduct", {
                ...this.product,
                has_stock,
                });
            },
        },
        stock: {
      get() {
        return this.$store.state.product.product.stock;
        },
        set(stock) {
            this.$store.commit("setProduct", {
            ...this.product,
            stock,
            });
        },
        },
        thank_you_page: {
            get() {
                return this.$store.state.product.product.thank_you_page;
            },
            set(thank_you_page) {
                this.$store.commit("setProduct", { ...this.product, thank_you_page });
            },
        },
        co_seller_display_id: {
            get() {
                return this.$store.state.product.product.co_seller_display_id;
            },
            set(co_seller_display_id) {
                this.$store.commit("setProduct", { ...this.product, co_seller_display_id });
            },
        },
        is_checkout_address: {
            get() {
                return !!this.$store.state.product.product.is_checkout_address;
            },
            set(is_checkout_address) {
                if (this.$store.state.product.product.format === "PHYSICALPRODUCT") {
                    this.$bvToast.toast(this.$t("views.seller.edit_product.text_2351"), {
                        title: this.$t("views.seller.edit_product.text_2270"),
                        variant: "info",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                }
                else {
                    this.$store.commit("setProduct", { ...this.product, is_checkout_address });
                }
            },
        },
        smart_sale: {
            get() {
                return !!this.$store.state.product.product.smart_sale;
            },
            set(smart_sale) {
                this.$store.commit("setProduct", { ...this.product, smart_sale });
            },
        },
        smart_sale_installments: {
            get() {
                return this.$store.state.product.product.smart_sale_installments;
            },
            set(smart_sale_installments) {
                this.$store.commit("setProduct", { ...this.product, smart_sale_installments });
            },
        },
        smart_sale_dynamic_installments: {
            get() {
                return !!this.$store.state.product.product.smart_sale_dynamic_installments;
            },
            set(smart_sale_dynamic_installments) {
                this.$store.commit("setProduct", { ...this.product, smart_sale_dynamic_installments });
            },
        },

    },
    methods: {
        teleportData() {
            EventBus.$emit("customThankYouPage", this.custom_thank_you_pages);
        },
        newThankYouPage() {
            this.custom_thank_you_pages.push({
                type: "CREDIT_CARD",
                url: "",
            });
        },
        removeThankYouPage(index) {
            this.custom_thank_you_pages.splice(index, 1);
        },
        fetchCustomPages() {
            serviceCustomThankYouPages.read({ id: this.$store.getters.productId })
                .then((response) => {
                    this.custom_thank_you_pages = response;
                });

            this.teleportData();
        },
        fetchCoSellers() {
            this.optionsCoProdutor = [{ value: null, text: this.$t("views.seller.edit_product.text_2344") }, ...this.coSellers.map(obj => {
                return {
                    value: obj.user.id,
                    text: obj.user.name
                }
            })]
        },
    },
    data() {
        return {
            optionsCoProdutor: [
                { value: null, text: this.$t("views.seller.edit_product.text_2344") },
            ],
            custom_list_all: [],
            custom_thank_you_pages: [],

            type_pages: [
                {
                    value: "CREDIT_CARD",
                    text: this.$t("views.seller.edit_product.text_2341"),
                },
                {
                    value: "BOLETO",
                    text: this.$t("views.seller.edit_product.text_2342"),
                },
                {
                    value: "TWO_CREDIT_CARD",
                    text: this.$t("views.seller.edit_product.text_2343"),
                },
                { value: "PIX", text: "PIX" },
                { value: "PAYPAL", text: "PAYPAL" },
            ],
            option_fixed_installments: [
                { value: null, text: this.$t("views.seller.edit_product.text_3133"), disabled: true },
                { value: 12, text: this.$t("views.seller.edit_product.text_2293") },
                { value: 11, text: this.$t("views.seller.edit_product.text_2294") },
                { value: 10, text: this.$t("views.seller.edit_product.text_2295") },
                { value: 9, text: this.$t("views.seller.edit_product.text_2296") },
                { value: 8, text: this.$t("views.seller.edit_product.text_2297") },
                { value: 7, text: this.$t("views.seller.edit_product.text_2298") },
                { value: 6, text: this.$t("views.seller.edit_product.text_2299") },
                { value: 5, text: this.$t("views.seller.edit_product.text_2300") },
                { value: 4, text: this.$t("views.seller.edit_product.text_2301") },
                { value: 3, text: this.$t("views.seller.edit_product.text_2302") },
                { value: 2, text: this.$t("views.seller.edit_product.text_2303") },
            ],
            validate_pre_selected: false,
            validate_pre_selected_two: false,
            Permissions,
        }
    },
    mounted: function () {
        this.fetchCoSellers();
        this.fetchCustomPages();
    },
    mixins: [Money],
};
</script>
