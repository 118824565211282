<template>
  <div>
    <!-- Event List -->
    <div v-if="!make" data-anima="top">
      <!-- Header -->
      <div class="flex-header">
        <div>
          <h5>{{ $t('views.cobranca.text_2132_1') }}</h5>
          <p>{{ $t('views.cobranca.text_891_1') }}</p>
        </div>
        <BaseButton variant="info-dark" class="custom-btn" @click="openBatchModal">
          {{ $t('views.cobranca.text_892_1') }}
        </BaseButton>
      </div>
      <div class="mt-4 overflow-table" data-anima="top">
        <!-- Table Body -->
        <template v-if="!loading && offers.length > 0">
          <draggable v-model="batch_groups" v-bind="dragOptionsGroups" class="draggable-custom overflow-auto px-1 pt-2" @start="dragGroup = true" @end="updateBatchOrderGroup">
            <transition-group type="transition" :name="!dragGroup ? 'flip-list' : null">
              <div v-for="(group, indexGroup) in batch_groups" :key="group.id" class="w-100 card-custom mb-3" style="min-width: 500px">
                <!-- Draggdrop -->
                <div class="custom-efect">
                  <div class="ml-4 d-flex justify-content-between py-3 pr-3 my-3">
                    <div class="d-flex align-items-center">
                      <!-- Expand -->
                      <div :id="'exp-' + indexGroup" class="mr-4 p-2 cursor-pointer custom-expanded" @click="expandedGroup(group, indexGroup)">
                        <img src="@/assets/img/icons/chevron-down.svg" />
                      </div>
                      <!-- Name -->
                      <div class="batch-name grabbable">
                        {{ group.name }}
                      </div>
                    </div>
                    <!-- Edit -->
                    <div class="edit-btn d-flex justify-content-center align-items-center" @click="openBatchModal(group)">
                      <span>{{ $t('views.cobranca.text_917') }}</span>
                    </div>
                  </div>
                  <div class="w-100 custom-efect draggdrop-body" :class="'group-body_' + group.id">
                    <draggable v-model="group.tickets" v-bind="dragOptions(indexGroup)" class="draggable-custom custom-efect" @start="drag = true" @end="updateTicketsOrder(group)">
                      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <div v-for="(item, index) in group.tickets" :key="item.id" class="mx-4 d-flex justify-content-between align-items-center mb-3">
                          <div class="d-flex">
                            <!-- Draggdrop -->
                            <div class="text-table grabbable mt-2 px-2" style="min-width: 38px;">
                              <img height="20" src="@/assets/img/icons/draggdrop.svg" />
                            </div>
                            <!-- Title -->
                            <div id="id_venda" class="text-table ml-3 mr-2 w-100" style="min-width: 190px;">
                              <div class="ticket-name mb-2" :class="{ 'heaven': isHeaven }">
                                {{ item.name }}
                              </div>
                              <span class="ticket-amount">{{ filterCurrency(item.currency_id) }} {{ formatMoney(+item.amount) }}</span>
                            </div>
                          </div>
                          <!-- Actions -->
                          <div class="mr-2">
                            <b-dropdown size="sm" dropleft variant="link" toggle-class="text-decoration-none" no-caret>
                              <template v-slot:button-content>
                                <img style="width: 25px;" src="@/assets/img/icons/more-event.svg" />
                              </template>
                              <b-dropdown-item class="drop-item" @click="editOffer(item)">
                                {{ $t('views.cobranca.text_917') }}
                              </b-dropdown-item>
                              <b-dropdown-item class="drop-item" @click="deleteOffer(item, index, group, indexGroup)">
                                {{ $t('views.cobranca.text_2395') }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                    <!-- Add Ticket -->
                    <div class="add-ticket">
                      <div class="ml-4 mt-3 mb-4">
                        <div class="btn-plus custom-efect d-flex justify-content-center align-items-center" @click="newOffer(group)">
                          <img class="mr-4" src="@/assets/img/icons/plus-event.svg" />
                          <div class="ml-2 add-ticket-text">
                            {{ $t('views.cobranca.text_918') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="add-ticket" :id="'count-' + indexGroup">
                  <div class="ml-4 mt-2 mb-2">
                    <div class="custom-efect d-flex align-items-center add-ticket-text">
                      {{ countTickets(group) }}
                      <div class="ml-2 add-ticket-text">
                        {{ ticketsAssigned(group) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </template>
        <!-- Loading -->
        <div v-if="loading" class="Table-body py-4 d-flex justify-content-center align-items-center">
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
        <!-- Feedback Message -->
        <b-row v-if="!loading && !offers.length" class="Table-body justify-content-center">
          <p class="nao-encontrado">Nenhum lote foi encontrado</p>
        </b-row>
      </div>
    </div>
    <!-- Create/Edit Offer -->
    <div v-else data-anima="top">
      <!-- Header -->
      <div class="flex-header">
        <div data-anima="top">
          <h5 class="pointer" @click="voltar()">
            <svg class="arrow" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.46967 9.53033C4.76256 9.82322 5.23744 9.82322 5.53033 9.53033C5.82322 9.23744 5.82322 8.76256 5.53033 8.46967L4.46967 9.53033ZM1 5L0.46967 4.46967C0.176777 4.76256 0.176777 5.23744 0.46967 5.53033L1 5ZM5.53033 1.53033C5.82322 1.23744 5.82322 0.762563 5.53033 0.46967C5.23744 0.176777 4.76256 0.176777 4.46967 0.46967L5.53033 1.53033ZM5.53033 8.46967L1.53033 4.46967L0.46967 5.53033L4.46967 9.53033L5.53033 8.46967ZM1.53033 5.53033L5.53033 1.53033L4.46967 0.46967L0.46967 4.46967L1.53033 5.53033Z" fill="#2133D2" />
            </svg>

            {{ $t('views.cobranca.text_919') }}
          </h5>
          <p>
            {{ $t('views.cobranca.text_899_1') }}
          </p>
        </div>
      </div>
      <b-form novalidate v-if="!loading">
        <div class="mt-4">
          <b-row>
            <!-- Name -->
            <b-col cols="12" md="9" class="mb-3 pr-3" data-anima="top">
              <b-form-group :label="$t('views.cobranca.text_920')" label-for="name">
                <b-form-input v-model="name" name="name" type="text" v-validate="'required'" :placeholder="$t('views.cobranca.text_901')" :class="{ 'heaven': isHeaven }"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t('views.cobranca.text_902') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- Currency -->
            <!-- <b-col v-if="renderComponent && isHeaven" cols="12" md="6">
              <b-form-group :label="$t('views.cobranca.text_915')" label-for="method" class="mr-3">
                <b-form-select
                  v-model="currencie_id"
                  :options="currencies"
                  value-field="id"
                  text-field="name"
                  :class="{'heaven': isHeaven}"
                  @change="changeCurrency"
                ></b-form-select>
              </b-form-group>
              <p v-if="product.smart_sale" class="mb-2" style="margin-top: -8px;">
                {{ $t('views.cobranca.text_916') }}
              </p>
            </b-col> -->
            <!-- Price -->
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group id="amount-container" :label="$t('views.cobranca.text_2132')" label-for="amount">
                <money id="amount" v-model="amount" v-bind="money" class="w-100" :class="{ 'heaven': isHeaven }" :disabled="editOfferIsDisable"></money>
              </b-form-group>
              <b-tooltip v-if="editOfferIsDisable" target="amount-container" :title="$t('views.cobranca.text_2143')" />
            </b-col>
          </b-row>
          <!-- Payment Methods -->
          <!-- <b-row v-if="renderComponent">
            <b-col cols="12" md="12">
              <b-form-group :label="$t('views.cobranca.text_2152')" label-for="method">
                <multiselect
                  id="multi_select_metodo"
                  v-model="method"
                  :options="methods_"
                  :value="method"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('views.cobranca.text_2152')"
                  label="name"
                  track-by="name"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  class="multi-select-input"
                  :class="{'heaven': isHeaven}"
                >
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row> -->
        </div>
      </b-form>
      <!-- Footer -->
      <div class="flex-footer">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          {{ $t('views.cobranca.text_2167') }}
        </BaseButton>
        <BaseButton variant="info2" :disabled="submit" @click="onSubmit">
          {{ $t('views.cobranca.text_921') }}
        </BaseButton>
      </div>
    </div>
    <BatchModal :batchGroup="batchGroup" @close="closeBatchModal" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Money } from 'v-money'
import Multiselect from 'vue-multiselect'
import Money2 from '@/mixins/money'
import BatchModal from '@/views/Seller/Event/BatchModal.vue'
import { textMethodType } from '@/helpers.js'
import { symbolCurrency } from '@/helpers.js'
import draggable from 'vuedraggable'
import { mapGetters } from "vuex";

import ProductService from '@/services/resources/ProductService'
import CurrencyService from '@/services/resources/CurrencyService'
import PresentialEventService from '@/services/resources/PresentialEventService'

const serviceProduct = ProductService.build()
const serviceCurrency = CurrencyService.build()
const servicePresentialEvent = PresentialEventService.build()

export default {
  name: 'Event',
  components: { Money, Multiselect, draggable, BatchModal },
  mixins: [Money2],
  props: [
    'isMobile',
    'warranty',
    'id_product',
    'methods',
    'option_fixed_installments',
    'co_sellers_list',
  ],
  data() {
    return {
      trial: "",
      renderComponent: true,
      option_fixed_installments_: [],
      charges: null,
      no_interest_installments: false,
      allowed_coupon: false,
      permite_parcela_boleto: false,
      max_subscription_installments: null,
      max_boleto_installments: 0,
      amount: "",
      method: [],
      methods_: [],
      currencie_id: 1,
      currencies: [],
      max_installments: 12,
      pre_selected_installment: null,
      fixed_installments: null,
      allow_offer_link: false,
      amount_first_charge: 0,
      custom_charges_bkp: false,
      custom_charges: [],
      custom_charges_is_available: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false
      },
      name: "",
      make: false,
      offers: [],
      loading: false,
      submit: false,
      defaultValue: 0,
      defaultValueCurrency: "R$",
      editItem: null,
      period: 365,
      subscription_installments: [
        { value: null, text: this.$t('views.seller.edit_product.text_2289'), disabled: true },
        { value: 1, text: this.$t('views.seller.edit_product.text_2290') },
      ],
      option_pre_selected_installment: [
        { value: null, text: this.$t('views.seller.edit_product.text_2304'), disabled: true },
        { value: 12, text: this.$t('views.seller.edit_product.text_2305') },
        { value: 11, text: this.$t('views.seller.edit_product.text_2306') },
        { value: 10, text: this.$t('views.seller.edit_product.text_2307') },
        { value: 9, text: this.$t('views.seller.edit_product.text_2308') },
        { value: 8, text: this.$t('views.seller.edit_product.text_2309') },
        { value: 7, text: this.$t('views.seller.edit_product.text_2310') },
        { value: 6, text: this.$t('views.seller.edit_product.text_2311') },
        { value: 5, text: this.$t('views.seller.edit_product.text_2312') },
        { value: 4, text: this.$t('views.seller.edit_product.text_2313') },
        { value: 3, text: this.$t('views.seller.edit_product.text_2314') },
        { value: 2, text: this.$t('views.seller.edit_product.text_2315') },
        { value: 1, text: this.$t('views.seller.edit_product.text_2316') },
        { value: 0, text: this.$t('views.seller.edit_product.text_2290') },
      ],
      periods: [
        { value: 30, text: this.$t('views.seller.edit_product.text_2284') },
        { value: 90, text: this.$t('views.seller.edit_product.text_2285') },
        { value: 180, text: this.$t('views.seller.edit_product.text_2286') },
        { value: 365, text: this.$t('views.seller.edit_product.text_2287') }
      ],
      drag: false,
      dragGroup: false,
      batch_groups: [],
      batchGroup: null,
      groupBatch: [],
      option_max_installments: [
        { value: null, text: this.$t("views.seller.edit_product.text_2289"), disabled: true },
        { value: 12, text: this.$t("views.seller.edit_product.text_2317") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2318") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2319") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2320") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2321") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2322") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2323") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2324") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2325") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2326") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2327") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2290") },
      ],
      acceptCard: false,
      acceptInvoice: false,
    }
  },
  computed: {
    ...mapGetters(["product"]),
    editOfferIsDisable() {
      return this.editItem && this.editItem.id ? true : false
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    dragOptionsGroups() {
      return {
        animation: 200,
        group: "groups",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  watch: {
    option_fixed_installments() {
      this.option_fixed_installments_ = this.option_fixed_installments
    },
    max_subscription_installments(value) {
      this.changeParcelasCard(value)
    },
    amount_first_charge(val) {
      if (parseInt(val) < 0) {
        this.resetFirstCharge();
      }
    },
    fixed_installments(val) {
      if (val && parseInt(val) > 0) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    allowed_coupon(val) {
      if (val) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    no_interest_installments(val) {
      if (val) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    custom_charges_is_available(val) {
      if (val && this.custom_charges.length === 0) {
        this.addNewCustomCharge(true)
        this.resetOptionsPlanOfPayment()
      }
    },
  },
  methods: {
    ticketsAssigned(group) {
      const numberOfTickets = group.tickets.length;
      return numberOfTickets > 1 ? this.$t('views.cobranca.text_30195_1') : this.$t('views.cobranca.text_30195')
    },
    countTickets(group) {
      const numberOfTickets = group.tickets.length;
      return numberOfTickets < 10 ? `${numberOfTickets === 0 ? '' : 0}${numberOfTickets}` : numberOfTickets;
    },
    dragOptions(i) {
      return {
        animation: 200,
        group: "tickets" + i,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    closeBatchModal() {
      this.fetchBatchs()
      this.batchGroup = null
    },
    openBatchModal(batch_group) {
      this.batchGroup = batch_group
      this.$bvModal.show("batch-modal")
    },
    showCount(group, i) {
      const count = document.getElementById('count-' + i)
      if (group.expanded) count.style.display = 'none'
      else count.style.display = 'block'
    },
    expandedGroup(group, i) {
      group.expanded = !group.expanded
      const exp = document.getElementById('exp-' + i)
      if (group.expanded) exp.style.transform = 'rotate(180deg)'
      else exp.style.transform = ''
      this.updateHeightCards()
      this.showCount(group, i)
    },
    updateHeightCards() {
      this.batch_groups.forEach(x => {
        const groupBody = document.getElementsByClassName('group-body_' + x.id)[0]
        const h = x.tickets.length * (50.65 + 16) + 68
        if (x.expanded) {
          if (groupBody) {
            groupBody.style.height = h + 'px'
            groupBody.style.paddingTop = '6px'
          }
        } else {
          if (groupBody) {
            groupBody.style.height = '0px'
            groupBody.style.paddingTop = '0px'
          }
        }
      })
    },
    filterCurrency(id) {
      return id ? symbolCurrency(id) : 'R$'
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toUpperCase()) {
          case "APPROVED":
            if (this.isHeaven) {
              r = { text: this.$t('views.cobranca.text_2410'), class: "is-green heaven" }
            } else {
              r = { text: this.$t('views.cobranca.text_2410'), class: "is-green" }
            }
            break
          case "PENDING":
            r = { text: this.$t('views.cobranca.text_2411'), class: "is-warning" }
            break
          case "DISAPPROVED":
            r = { text: this.$t('views.cobranca.text_2412'), class: "is-red" }
            break
          default:
            r = { text: status, class: "is-black" }
            break
        }
        return r
      } else return { text: status, class: "is-black" }
    },
    validMethod() {
      var methods = this.method
      var result = methods.filter((x) => x.value === 'BOLETO')
      return JSON.stringify(result) === '[]' ? false : true
    },
    validStatusNew() {
      let offers = this.offers;
      let result = offers.filter((x) => x.status === 'PENDING');
      let validateSatus = this.product.status === 'PENDING' || this.product.status === 'DISAPPROVED' || this.product.status === 'REQUESTED'
      return validateSatus
        ? this.$t('views.cobranca.text_2408')
        : JSON.stringify(result) === '[]' ? false : this.$t('views.cobranca.text_2409')
    },
    validStatus(status) {
      var offers = this.offers
      var result = offers.filter(x => x.status === 'PENDING' && x.default === 1)
      return JSON.stringify(result) === '[]'
        ? (status === 'PENDING' || status === 'DISAPPROVED' ? false : true)
        : false
    },
    voltar() {
      this.$validator.pause()
      this.make = false
      this.$emit("changeValue", false)
      this.reset()
    },
    changeParcelasCard(value) {
      let totalParcelas = this.max_installments

      if (totalParcelas < this.pre_selected_installment) this.pre_selected_installment = null

      this.option_pre_selected_installment = [
        { value: null, text: this.$t('views.cobranca.text_2407'), disabled: true }
      ]

      for (let i = 1; i <= totalParcelas; i++) {
        if (i > 1) {
          this.option_pre_selected_installment.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
        }
      }
    },
    changeParcelasSub() {
      var period = this.period
      this.fixed_installments = null

      period = 365
      if (period === 30) {
        this.pre_selected_installment = null
        return
      }

      let totalParcelas = 0
      switch (period) {
        case 90:
          totalParcelas = 3
          break
        case 180:
          totalParcelas = 6
          break
        case 365:
          totalParcelas = 12
          break
      }

      this.option_fixed_installments_ = [
        { value: null, text: this.$t('views.cobranca.text_2404') }
      ]
      this.subscription_installments = [
        { value: null, text: this.$t('views.cobranca.text_2405'), disabled: true },
        { value: 1, text: this.$t('views.cobranca.text_2406') }
      ]
      this.option_pre_selected_installment = [
        { value: null, text: this.$t('views.cobranca.text_2407'), disabled: true }
      ]

      for (let i = 1; i <= totalParcelas; i++) {
        if (i > 1) {
          this.option_fixed_installments_.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
          this.option_pre_selected_installment.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
          this.subscription_installments.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
        }
      }
    },
    makeNew(item) {
      this.$emit('changeValue', true)
      this.name = this.product.name
    },
    newOffer(item) {
      this.changeParcelasSub()
      this.groupBatch = item;
      this.make = true;
    },
    async editOffer(item) {
      this.checkPaymentMethods(item.method)

      this.groupBatch = this.batch_groups.find(x => x.id == item.offer_group_id)

      if (item.method === null) item.method = ''

      var methodItem = item.method.split(',')
      this.currencie_id = item.currency_id ? item.currency_id : 1
      let getAllMethods = await this.changeCurrency(this.currencie_id, 'MOUNTED')
      var methodAll = this.methods_
      var arrayMethod = []

      if (methodItem[0] === 'ALL') {
        for (let i = 0; i < methodAll.length; i++) {
          arrayMethod.push(methodAll[i])
        }
      } else {
        for (let i = 0; i < methodAll.length; i++) {
          const elementAll = methodAll[i]
          for (let x = 0; x < methodItem.length; x++) {
            if (elementAll.value === methodItem[x]) {
              arrayMethod.push(elementAll)
            }
          }
        }
      }

      if (
        item.max_installments &&
        item.max_installments !== 1 &&
        item.max_installments !== ""
      ) {
        this.max_installments = item.max_installments;
      } else {
        this.max_installments = 12;
      }

      if (item.no_interest_installments === 1) {
        this.no_interest_installments = true;
      } else {
        this.no_interest_installments = false;
      }

      if (item.allowed_coupon === 1) {
        this.allowed_coupon = true;
      } else {
        this.allowed_coupon = false;
      }

      if (item.pre_selected_installment) {
        this.pre_selected_installment = item.pre_selected_installment;
      } else {
        this.pre_selected_installment = item.pre_selected_installment;
      }

      if (item.max_boleto_installments) {
        this.permite_parcela_boleto = true;
        this.max_boleto_installments = item.max_boleto_installments;
      } else {
        this.permite_parcela_boleto = false;
        this.max_boleto_installments = 0;
      }

      if (item.max_installments) {
        this.max_installments = item.max_installments;
      } else {
        this.max_installments = 12;
      }

      if (item.max_subscription_installments) {
        this.max_subscription_installments = item.max_subscription_installments;
      } else {
        this.max_subscription_installments = 12;
      }

      if (item.allow_offer_link) {
        this.allow_offer_link = true;
      } else {
        this.allow_offer_link = false;
      }

      if (item.custom_charges && item.custom_charges.length > 0) {
        this.custom_charges = item.custom_charges
        this.custom_charges_is_available = true;
      } else {
        this.custom_charges = null
      }

      this.$emit("changeValue", true);
      this.editItem = item;
      this.name = item.name;
      this.trial = item.trial;
      this.charges = item.charges;
      this.period = item.period;

      this.period = 365;

      (this.amount = item.amount), (this.method = arrayMethod);

      await this.changeParcelasSub();

      if (
        item.fixed_installments &&
        item.fixed_installments !== 1 &&
        item.fixed_installments !== ""
      ) {
        this.fixed_installments = item.fixed_installments;
      } else {
        this.fixed_installments = null;
      }

      this.make = true;
    },
    filterDefault() {
      const offer = this.offers.find(o => o.default === 1)
      this.defaultValue = offer.amount
      this.defaultValueCurrency = offer.currency_id
    },
    changeDefault(item) {
      if (item.default === 1) {
        this.$bvToast.toast(this.$t('views.cobranca.text_2168'), {
          title: this.$t('views.cobranca.text_2169'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }
      item.id = `${this.$route.params.id_product}/offers/${item.id}`
      delete item.created_at
      delete item.updated_at
      item.default = 1

      this.loading = true
      serviceProduct
        .update(item)
        .then(() => {
          this.resolveResponse()
          this.filterDefault()
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    deleteOffer(item, index, group, indexGroup) {
      if (item.default === 1) {
        this.$bvToast.toast(this.$t('views.cobranca.text_911'), {
          title: this.$t('views.cobranca.text_2169'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      Vue.swal({
        title: item.deleted_at ?  this.$t("views.cobranca.text_925") : this.$t("views.cobranca.text_897"),
        text: `${item.deleted_at ? this.$t("views.cobranca.text_926") : this.$t("views.cobranca.text_912")} ${item.name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: item.deleted_at ? this.$t("views.cobranca.text_923") : this.$t("views.seller.products.text_1461"),
        cancelButtonText: this.$t('views.cobranca.text_2167'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true

          serviceProduct
            .destroy({ id: `${this.$route.params.id_product}/offers/${item.id}` })
            .then(() => {
              this.$bvToast.toast(item.deleted_at ? this.$t("views.cobranca.text_924") : this.$t("views.cobranca.text_913"), {
                title: this.$t('views.cobranca.text_2169'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true
              })
              group.tickets.splice(index, 1)
              if (group.length === 0) {
                this.batch_groups.splice(indexGroup, 1)
              }
              this.fetchBatchs()
            })
            .catch(err => console.error(err))
            .finally(() => { this.loading = false })
        }
      });
    },
    getMethod(method) {
      if (method === 'ALL') return 'Todos'
      if (method === null) return ''

      method = method.split(',')
      var array = []
      for (let i = 0; i < method.length; i++) {
        const element = this.textMethod(method[i])
        array.push(element)
      }
      return array.toString()
    },
    reset() {
      this.editItem = null
      this.max_boleto_installments = 0
      this.no_interest_installments = false
      this.allowed_coupon = false
      this.permite_parcela_boleto = false
      this.amount = ''
      this.name = ''
      this.currencie_id = 1
      this.method = []
      this.max_installments = 12
      this.fixed_installments = null
      this.custom_charges = []
      this.custom_charges_is_available = false
      this.custom_charges_bkp = false
    },
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    cancel() {
      this.$validator.pause()
      this.make = false
      this.$emit("changeValue", false)
      this.fetchOffers()
      this.reset()
    },
    resetFirstCharge() {
      if (this.custom_charges_is_available) {
        this.custom_charges_bkp = this.custom_charges;
        this.custom_charges = [];
        this.custom_charges_is_available = false;
        this.$bvToast.toast(this.$t('views.cobranca.plan_of_payment_has_unactivated'), {
          title: this.$t('views.cobranca.plan_of_payment'),
          variant: "warning",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    resetOptionsPlanOfPayment() {
      this.max_subscription_installments = 1
      this.fixed_installments = null
      this.pre_selected_installment = null
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          var method = this.method;
          var array_method = [];
          for (let i = 0; i < method.length; i++) {
            const element = method[i];
            if (this.product.format == "PHYSICALPRODUCT" && element.value == "PAYPAL") {
              this.$bvToast.toast(
                this.$t("views.seller.edit_product.text_3000"),
                {
                  title: "Paypal",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              array_method = [];
              return
            } else {
              array_method.push(element.value);
            }
          }

          // if (!array_method.join().length) {
          //   this.$bvToast.toast(this.$t('views.cobranca.text_2402'), {
          //     title: this.$t('views.cobranca.text_2169'),
          //     variant: "danger",
          //     autoHideDelay: 5000,
          //     appendToast: true,
          //   });
          //   this.submit = false;
          //   this.loading = false;
          //   return;
          // }

          if (this.co_sellers_list && this.co_sellers_list.length) {
            if (this.amount < 15) {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 15,00`, {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }
          } else {
            if (this.amount < 9) {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 9,00`, {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }
          }

          let data = {
            id: `${this.$route.params.id_product}/offers/`,
            method: array_method.join(),
            name: this.name,
            currency_id: this.isHeaven ? 2 : 1,
            amount: this.amount,
            fixed_installments: this.fixed_installments,
            no_interest_installments: this.no_interest_installments,
            allowed_coupon: this.allowed_coupon,
            default: 0,
            pre_selected_installment: this.pre_selected_installment,
            allow_offer_link: this.allow_offer_link,
            offer_group_id: this.groupBatch.id
          };

          if (this.custom_charges) {
            if (this.custom_charges.length > 0 && this.custom_charges_is_available) {
              let invalidCustomFind = false;
              this.custom_charges.map(custom_charge => {
                if (parseFloat(custom_charge.amount || 0) < 1) {
                  invalidCustomFind = true;
                }
              })
              if (invalidCustomFind) {
                this.$bvToast.toast(
                  this.$t("views.cobranca.min_charge_amount"),
                  {
                    title: this.$t("views.cobranca.plan_of_payment"),
                    variant: "warning",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.submit = false;
                this.loading = false;
                return;
              }
              data.custom_charges = this.custom_charges;
            }
          }

          if (this.fixed_installments) {
            data.pre_selected_installment = null;
          }

          data.max_installments = this.max_installments || 12;

          // validação parcelas de assinatura
          this.max_subscription_installments = 1;

          if (this.permite_parcela_boleto) {
            data.max_boleto_installments = this.max_boleto_installments;
          } else {
            data.max_boleto_installments = 0;
          }

          if (this.editItem) {
            delete data.offer_group_id

            data.id = `${this.$route.params.id_product}/offers/${this.editItem.id}`
            serviceProduct
              .update(data)
              .then(() => this.resolveResponse())
              .catch(err => console.error(err))
              .finally(() => { this.submit = false; this.loading = false; })
            return
          }

          serviceProduct
            .createId(data)
            .then(() => this.resolveResponse())
            .catch(err => console.error(err))
            .finally(() => { this.submit = false; this.loading = false; })
        }
      });
    },
    async resolveResponse() {
      this.$bvToast.toast(this.$t('views.cobranca.text_914'), {
        title: this.$t('views.cobranca.text_2169'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true
      })
      this.reset()
      await this.fetchOffers()
      await this.fetchBatchs()
      this.$validator.pause()
      this.make = false
      this.$emit("changeValue", false)
    },
    async changeCurrency(value, mounted) {
      await this.forceRerender()
      this.money.prefix = symbolCurrency(value)

      serviceCurrency
        .read({ id: '/payment_methods/' + value })
        .then((response) => {
          if (mounted === undefined) {
            this.method = [];
          }
          this.methods_ = [];
          response.forEach((element) => {
            this.methods_.push({
              value: textMethodType(element.payment_methods.name).toUpperCase(),
              name: this.textMethod(element.payment_methods.name).toUpperCase()
            })
          })
          this.forceRerender()
        })
        .catch(err => console.error(err))
    },
    fetchCurrency() {
      this.currencies = []

      serviceCurrency
        .read({ id: '/currencies' })
        .then((response) => {
          response.forEach(({ id, name }) => this.currencies.push({ id, name }))
        })
        .catch(err => console.error(err))
    },
    async fetchOffers() {
      this.loading = true

      await serviceProduct
        .read({ id: this.$route.params.id_product + '/offers' })
        .then(response => {
          this.offers = response
          this.filterDefault()
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    textMethod(method) {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },
    addNewCustomCharge(firstAdd) {
      if (this.custom_charges.length === 4) {
        this.$bvToast.toast(
          this.$t('views.cobranca.limitCustomCharges'),
          {
            title: this.$t('views.cobranca.plan_of_payment'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        return;
      }
      if (firstAdd) {
        this.custom_charges = [
          {
            sequence: 1,
            amount: '0'
          }
        ]
      } else {
        this.custom_charges.push({
          sequence: this.custom_charges.length + 1,
          amount: '0'
        })
      }

    },
    removeCustomCharge(index) {
      let newCustomCharges = [];
      this.custom_charges.map((c, i) => {
        let c_charge = c;
        if (i !== index) {
          newCustomCharges.push({
            ...c_charge,
            sequence: newCustomCharges.length + 1
          })
        }
      })
      this.custom_charges = newCustomCharges;
    },
    restoreCustomCharge() {
      if (this.custom_charges_bkp) {
        this.custom_charges = this.custom_charges_bkp;
      }
    },
    updateBatchOrderGroup() {
      const updateOrderGroup = this.batch_groups.map((item, index) => {
        item.order = index + 1
        return {
          id: item.id,
          old_order: item.order,
          new_order: index + 1
        }
      });
      this.dragGroup = false
      servicePresentialEvent.create(updateOrderGroup, '/offer-groups/order')
        .catch(err => console.error(err))
    },
    updateTicketsOrder(group) {
      const updateTickets = group.tickets.map((item, index) => {
        item.batch_order = index + 1
        return {
          ...item,
          new_order: index + 1
        }
      });
      this.drop = false
      servicePresentialEvent.create(updateTickets, '/order')
        .catch(err => console.error(err))
    },
    async fetchBatchs() {
      return await servicePresentialEvent.read({ id: `/offer-groups/${this.$route.params.id_product}` })
        .then(res => {
          if (this.product.product_type_id === 3) {
            res.sort((a, b) => {
              if (a.order < b.order) return -1
              if (a.order > b.order) return 1
              return 0
            })
            res.forEach(x => {
              x.tickets.sort((a, b) => {
                if (a.batch_order < b.batch_order) return -1
                if (a.batch_order > b.batch_order) return 1
                return 0
              })
            })
          }
          this.batch_groups = res
        })
        .catch(err => console.error(err))
    },
    checkPaymentMethods(methods) {
      this.acceptCard = false
      this.acceptInvoice = false
      let allMethods = methods.split(",")

      allMethods.forEach(element => {
        if (element == "CREDIT_CARD" || element == "TWO_CREDIT_CARDS")
          return this.acceptCard = true

        if (element == "BOLETO")
          return this.acceptInvoice = true
      });
    }
  },
  mounted() {
    this.methods_ = this.methods;
    this.fetchOffers();
    this.fetchCurrency();
    this.fetchBatchs();

    if (this.isHeaven) {
      this.changeCurrency(2, "MOUNTED");
      this.defaultValueCurrency = '$'
    } else {
      this.changeCurrency(1, "MOUNTED");
    }
  },
}
</script>

<style>
.dropdown-menu {
  padding: 0 !important;
}

.custom-btn {
  border-radius: 10px;
}

.edit-btn {
  width: 76px;
  height: 35px;
  border-radius: 5px;
  background-color: #F7F7F7;
  cursor: pointer;
  color: #333;
  font-size: 13px;
  font-weight: 600;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.edit-btn:hover {
  background-color: #e6e6e6;
  color: #333;
  transform: scale(1.1);
}

.add-ticket {
  border-top: 1px solid #E5E5E5;
}

.card-custom {
  border: 1px solid #E5E5E5;
  border-radius: 10px;
}

.grabbable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.no-move {
  transition: transform 0s;
}

.flip-list-move {
  transition: transform 0.5s;
}

.draggable-custom {
  width: 100%;
}

.draggable-custom span {
  width: fit-content;
}
</style>

<style lang="scss" scoped>
.add-ticket-text {
  color: #81858E;
  font-size: 16px;
  font-weight: 400;
}

.add-ticket-text:hover {
  color: #62656d;
}

.btn-plus {
  cursor: pointer;
  width: 255px;
  border: 0 solid transparent;
  border-radius: 50px;
  padding: 3px 0;
}

.overflow-auto {
  overflow: auto;
}

.group-draggdrop {
  height: 88px;
}

.draggdrop-body {
  overflow: hidden;
  height: 0;
}

.custom-efect {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.custom-expanded {
  border: solid 0 transparent;
  border-radius: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.custom-expanded:hover {
  background-color: #F1F2F3;
}

.batch-name {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.ticket-name {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.ticket-amount {
  color: #058A2A;
  font-size: 15px;
  font-weight: 400;
}

.overflow-table {
  width: 849px;
}

@media (max-width: 1200px) {
  .overflow-table {
    width: 700px;
  }
}

@media (max-width: 1000px) {
  .overflow-table {
    overflow-x: auto;
    width: 600px;
  }
}

@media (max-width: 800px) {
  .overflow-table {
    overflow-x: auto;
    width: 500px;
  }
}

@media (max-width: 600px) {
  .overflow-table {
    overflow-x: auto;
    width: 400px;
  }
}

@media (max-width: 400px) {
  .overflow-table {
    overflow-x: auto;
    width: 300px;
  }
}

h5,
p {
  margin: 0;
}

h5 {
  margin-bottom: 20px;
  color: #2133d2;
  font-weight: 600;
  font-size: 14px;
}

p {
  font-weight: normal;
  font-size: 13px;
  color: #81858e;
}

h2 {
  font-weight: 500;
  font-size: 24px;
  color: #333;
}

#id_venda {
  position: relative;
}

.copy-link {
  width: 15px;
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}

.copy-link:hover {
  filter: invert(0);
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}

.is-green::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #00E4A0 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 64px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.is-warning::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #FFCB37 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 64px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.is-black::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #333 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 64px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.is-red::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #FF0C37 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 58px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}


.text-table {
  font-weight: normal !important;
  font-size: 13px !important;
  color: #333333;
}

.default {
  background: rgba(0, 228, 160, 0.1);
  border-radius: 5px;
  height: 27px;
  text-align: center;
  padding: 4px 1px;
  position: relative;
  left: -10px;
  color: $base-color !important;
  font-weight: 500 !important;
  margin-right: 0 !important;
  width: 110px;
}

.default.heaven {
  background: $base-color-heaven-light;
  color: $base-color-heaven !important;
}

.type {
  display: grid;
  grid-template-columns: 60px 4fr 60px;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid 1px #ededf0;

  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
}

.info-value {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.arrow {
  position: relative;
  left: -15px;
}

.pointer {
  cursor: pointer;
}

.value-base {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;

  svg {
    margin-left: 10px;
  }
}

.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-footer {
  display: flex;
  justify-content: flex-end;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

.plan-payment-container {
  display: flex;
  width: 100%;
  color: #333333;
}

.charge-container {
  display: flex;
  min-width: 100% !important;
  margin-top: 12px;
  flex-direction: column;
  padding-bottom: 24px;
}

.charge-row {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
}

.charge-info {
  width: 236px;
  margin-top: 35px;
  background: rgba(20, 0, 255, 0.05);
  border-radius: 5px;
  padding: 20px;
}

.charge-info-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */


  color: #1400FF;
}

.font-header-charge {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */


  color: #333333;
}

.info-checkbox {
  cursor: pointer;
  font-weight: 600;
  color: #333;
}
</style>
