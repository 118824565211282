<template>
  <section>
    <list-item
      v-if="!loading"
      title="Webhook"
      :list="list"
      @update="editWebhook"
      @delete="removeWebhook"
      @test="testeWebhook"
    />
    <div class="container-loading" v-else>
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <webhook-modal :webhook="toUpdate" @close="resetListLoading" @updated="saveEditWebhook"/>
  </section>
</template>

<script>
import Vue from "vue";

// helper:
import { EventBus } from "@/main.js";

// componentes:
import ModalResponse from "./ModalResponse";
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";
import WebhookModal from "@/components/Seller/Products/modals/UpdateWebhook";
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();
// vuex
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListagemWebhook",
  data() {
    return {
      loading: false,
      lista: [],
      toUpdate: {},
      list:[],
    };
  },
  components: {
    ModalResponse,
    ListItem,
    WebhookModal
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  watch:{
    getWebhooks(){
      if(this.getWebhooks.length){
        this.list = this.getWebhooks.map((item) => ({
          id: item.id,
          icon: "share.svg",
          name: `#${item.id} - ${item.name}`,
          description: item.url,
          actions: ["update", "delete", "test"],
        }));
      } else {
        this.list = [];
      }
    }
  },
  computed: {
    ...mapGetters({
      getWebhooks: 'getWebhooks',
    }),
  },
  methods: {
    ...mapActions([
      'fetchWebhooks',
    ]),
    async testeWebhook(payload) {
      const { name, id } = payload;
      Vue.swal({
        title: `⚡ ${this.$t("seller.products.listagem_webhook.text_533")}`,
        text: `${this.$t(
          "seller.products.listagem_webhook.text_534"
        )} ${name}?`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.webhook.text_751"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = {
            id: `/webhook/${id}`,
          };

          await serviceCallback
            .update(data)
            .then((response) => {
              if (response.success) {
                this.$bvToast.toast(
                  `${this.$t("seller.products.listagem_webhook.text_535")}`,
                  {
                    title: "Webhook",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              } else {
                this.$bvToast.toast(
                  `${this.$t("seller.products.listagem_webhook.text_536")}`,
                  {
                    title: `Webhook`,
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.$bvModal.show("modal-response");
              }
            })
            .catch((error) => {
               this.$bvToast.toast(
                  `${this.$t("seller.products.listagem_webhook.text_536")}`,
                  {
                    title: `Webhook`,
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              console.error(error);
            });
        }
      });
    },
    async saveEditWebhook(payload) {
      this.loading = true;

      await serviceCallback
        .update(payload)
        .then(async() => {
          this.$bvToast.toast(
            `${this.$t("seller.products.listagem_webhook.text_537")}`,
            {
              title: "Webhook",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          await this.fetchWebhooksVuex();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
          this.toUpdate = {};
          this.resetListLoading();
          this.$bvModal.hide("update-webhook-modal");
        });
    },
    editWebhook(payload) {
      const { id } = payload;
      const item = this.lista.find((item) => item.id == id);
      this.toUpdate = item;
      this.$bvModal.show("update-webhook-modal");
    },
    async removeWebhook(payload) {
      const { id, index, name } = payload;
      Vue.swal({
        title: `${this.$t("seller.products.listagem_webhook.text_538")}`,
        text: `${this.$t(
          "seller.products.listagem_webhook.text_539"
        )} ${name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;

          await serviceCallback
            .destroy({ id })
            .then(async(response) => {
              if (response == 1) {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_webhook.text_540"),
                  {
                    title: "Webhook",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                // atualizando front
                this.lista.splice(index, 1);
                let data = {
                  product_id: this.id_product,
                  type: "webhook",
                  this:this
                };
                await this.fetchWebhooks(data).then(() => {
                  EventBus.$emit("integracoesLista", -1);
                }).catch(() => {
                  this.$bvToast.toast(
                    this.$t("seller.products.listagem_webhook.text_541"),
                    {
                      title: "Webhook",
                      variant: "danger",
                      autoHideDelay: 5000,
                      appendToast: true,
                    }
                  );
                })
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_webhook.text_541"),
                  {
                    title: "Webhook",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => console.error(err))
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    async fetchWebhooksVuex() {
      this.loading = true;
      let data = {
        product_id: this.id_product,
        type: "webhook",
        this:this
      };
      await this.fetchWebhooks(data)
        .then(() => {
          EventBus.$emit("integracoesLista", this.lista.length);
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
          this.loading = false;
        })
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid":
          return this.$t("seller.products.options_sales.text_751");
        case "saleRefused":
          return this.$t("seller.products.options_sales.text_752");
        case "saleRefunded":
          return this.$t("seller.products.options_sales.text_753");
        case "BOLETO":
          return this.$t("seller.products.options_saleswaiting.text_758");
        case "PIX":
          return this.$t("seller.products.options_saleswaiting.text_759");
        case "saleChargedback":
          return this.$t("seller.products.options_sales.text_754");
        case "saleUnpaid":
          return this.$t("seller.products.options_sales.text_755");
        case "saleWaitingPayment":
          return this.$t("seller.products.options_sales.text_756");

        //  assinaturas
        case "contractUnpaid":
          return this.$t("seller.products.options_sub.text_773");
        case "contractPaid":
          return this.$t("seller.products.options_sub.text_774");
        case "contractTrialing":
          return this.$t("seller.products.options_sub.text_775");
        case "contractPendingPayment":
          return this.$t("seller.products.options_sub.text_776");
        case "contractEnded":
          return this.$t("seller.products.options_sub.text_777");

        //  checkout
        case "checkoutAbandoned":
          return this.$t("seller.products.options_checkout.text_779");

        //  venda recusada
        case "INVALID_CREDIT_CARD":
          return this.$t("seller.products.options_salesrefused.text_761");
        case "INSUFFICIENT_FUNDS":
          return this.$t("seller.products.options_salesrefused.text_762");
        case "INVALID_PAYMENT_TYPE":
          return this.$t("seller.products.options_salesrefused.text_763");
        case "INVALID_DATA":
          return this.$t("seller.products.options_salesrefused.text_764");
        case "INVALID_CLIENT_DATA":
          return this.$t("seller.products.options_salesrefused.text_765");
        case "INVALID_CVV":
          return this.$t("seller.products.options_salesrefused.text_766");
        case "BANK":
          return this.$t("seller.products.options_salesrefused.text_767");
        case "CREDIT_CARD_OPERATOR":
          return this.$t("seller.products.options_salesrefused.text_768");
        case "INVALID_INSTALLMENTS":
          return this.$t("seller.products.options_salesrefused.text_769");
        case "CURRENCY_NOT_SUPPORTED":
          return this.$t("seller.products.options_salesrefused.text_770");
        case "SUSPECTED_FRAUD":
          return this.$t("seller.products.options_salesrefused.text_771");
        case "GENERIC":
          return this.$t("seller.products.options_salesrefused.text_772");
        default:
          return trigger;
      }
    },
    resetListLoading() {
      this.$store.dispatch("setListLoading", true);
      this.$nextTick(() => {
        this.$store.dispatch("setListLoading", false);
      });
    },
  },
  async mounted() {
    await this.fetchWebhooksVuex();
  },
};
</script>