import { render, staticRenderFns } from "./UpdateWebhook.vue?vue&type=template&id=80c67366&scoped=true&lang=true&"
import script from "./UpdateWebhook.vue?vue&type=script&lang=js&"
export * from "./UpdateWebhook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80c67366",
  null
  
)

export default component.exports