<template>
  <b-modal
    id="batch-modal"
    name="batch-modal"
    size="md"
    @shown="onOpenModal"
    @hide="resetFields"
  >
    <template v-slot:modal-title>
      <h5 class="mt-4 batch-modal-title">
        {{ dynamicTitle }}
      </h5>
      <small class="batch-modal-subtitle">
        {{ $t('seller.event.text_2890') }}
      </small>
    </template>

    <b-row>
      <!-- Batch Name -->
      <b-col cols="12">
        <b-form-group class="mt-3 mt-md-0" :label="'Título do lote'" label-for="name">
          <b-form-input
            id="name"
            name="name"
            :placeholder="'Ex: Primeiro Lote'"
            v-model="batch.name"
            type="text"
            v-validate="'required'"
            :class="{'heaven': isHeaven}"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('name')">
            {{ $t('seller.products.new.text_588') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Offer Name -->
      <b-col cols="12" v-if="!batchGroup && !batch.id">
        <b-form-group class="mt-3 mt-md-0" :label="'Título do tipo de ingresso'" label-for="offer_name">
          <b-form-input
            id="offer_name"
            name="offer_name"
            :placeholder="'Ex: Inteira, Meia, Vip...'"
            v-model="batch.offer_name"
            type="text"
            v-validate="'required'"
            :class="{'heaven': isHeaven}"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('offer_name')">
            {{ $t('seller.products.new.text_588_1') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Release Type -->
      <b-col class="mt-3" cols="12">
        <b-form-group :label="$t('seller.event.text_2884')" label-for="release_type">
          <b-form-select
            id="release_type"
            name="release_type"
            v-model="batch.release_type"
            :options="releaseTypes"
            :class="{'heaven': isHeaven}"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <!-- Release Fixed Date -->
      <b-col v-if="batch.release_type === 'fixed_date'" cols="12">
        <b-form-group
          :label="$t('seller.products.new.text_598_2')"
          label-for="release_fixed_date"
        >
          <input
            :placeholder="$t('seller.products.new.text_598_1')"
            type="date"
            id="release_fixed_date"
            name="release_fixed_date"
            v-model="batch.release_fixed_date"
            class="mb-2"
            :class="{'heaven': isHeaven}"
            required
            v-validate="'required'"
            style="width: 100%;"
            step="1"
           >
          <b-form-invalid-feedback :state="!errors.has('release_fixed_date')">
            {{ $t('seller.products.new.text_599_1') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Release Fixed Time -->
      <b-col v-if="batch.release_type === 'fixed_date'" class="mt-3" cols="12">
        <b-form-group :label="$t('seller.products.new.text_597_4')" label-for="release_fixed_time">
          <input
            :placeholder="$t('seller.products.new.text_597_3')"
            type="time"
            id="release_fixed_time"
            name="release_fixed_time"
            v-model="release_fixed_time"
            class="mb-2"
            :class="{'heaven': isHeaven}"
            required
            v-validate="'required'"
            style="width: 100%;"
            step="60"
           >
          <b-form-invalid-feedback :state="!errors.has('release_fixed_time')">
            {{ $t('seller.products.new.text_599_2') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Which Batch Depends -->
      <b-col v-if="batch.release_type === 'offer_group_stock'" cols="12">
        <b-form-group :label="$t('views.cobranca.text_2171_4')" label-for="release_offer_group_stock_id">
          <b-form-select
            id="release_offer_group_stock_id"
            name="release_offer_group_stock_id"
            v-model="batch.release_offer_group_stock_id"
            :options="batch_groups_list"
            :class="{'heaven': isHeaven}"
            v-validate="'required'"
          ></b-form-select>
          <b-form-invalid-feedback :state="!errors.has('release_offer_group_stock_id')">
            {{ $t('views.cobranca.text_2171_5') }}
          </b-form-invalid-feedback>
        </b-form-group>
        
      </b-col>
      <!-- Tickets -->
      <b-col cols="12" v-if="batch.release_type === 'offer_group_stock' || batch.release_type === 'by_stock'">
        <b-form-group
          :label="$t('seller.products.new.text_602_1')"
          label-for="max_paid_sales"
          data-anima="top"
          class=""
        >
          <b-form-input
            id="max_paid_sales"
            placeholder="30"
            name="max_paid_sales"
            v-model="batch.max_paid_sales"
            v-validate="'required|numeric|min_value:1'"
            type="number"
            :class="{'heaven': isHeaven}"
          ></b-form-input>
        </b-form-group>
        <b-form-invalid-feedback :state="!errors.has('max_paid_sales')">
          {{ $t('seller.products.new.text_603_1') }}
        </b-form-invalid-feedback>
      </b-col>
      <!-- Amount -->
      <b-col cols="12" md="6" v-if="!batchGroup && !batch.id" class="mt-3">
        <b-form-group :label="$t('seller.products.new.text_610')" label-for="amount">
          <money
            id="amount"
            v-model="amount"
            v-bind="money"
            :class="{'heaven': isHeaven}"
          ></money>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Footer -->
    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end mb-4 w-100">
        <div class="d-md-flex w-100">
          <BaseButton variant="gray" class="mr-3 mb-2 w-100" @click="cancel">
            {{ $t('seller.event.text_2881') }}
          </BaseButton>
          <BaseButton variant="primary" @click="onSubmit" class="w-100" style="min-width: 229px;">
            {{ $t('seller.event.text_2882') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { Money } from "v-money";

import PresentialEventService from '@/services/resources/PresentialEventService';

const servicePresentialEvent = PresentialEventService.build();

export default {
  name: 'BatchModal',
  components: { Money },
  props: ['batchGroup'],
  data() {
    return {
      amount:0,
      batch: {
        name: null,
        offer_name: '',
        offer_amount: 0,
        max_paid_sales: null,
        release_offer_group_stock_id: null,
        release_type: null,
        release_fixed_date: null,
        release_by_stock: null,
        release_no_rules: null,
      },
      loading: false,
      batch_groups: [],
      release_fixed_time: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      }
    }
  },
  computed: {
    dynamicTitle(){
      return this.batch.id ? this.$t('seller.event.text_2889') : 'Novo Lote';
    },
    releaseTypes() {
      return [
        { value: null, text: 'Sem regra' },
        { value: "offer_group_stock", text: 'Outro lote esgotar os ingressos', disabled: this.batch_groups_list.length === 0 },
        { value: "fixed_date", text: 'Data limite para vendas' },
        { value: "by_stock", text: 'Por estoque' },
      ]
    },
    batch_groups_list() {
      let list = []
      list = this.batch_groups.map(x => {
        return { value: x.id, text: x.name, type: x.release_type, disabled: x.release_type === null }
      })

      if(this.batchGroup && this.batchGroup.id) {
        return list.filter(x => {
          return x.value !== this.batchGroup.id && x.type !== null
        })
      } else return list
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
  },
  watch: {
    amount(value){
      this.batch.offer_amount = value;
    },
    'batch.release_type'() {
      if(this.batch.release_type === 'offer_group_stock' && !this.batch_groups.length) {
        this.fetchBatchs()
      }
    }
  },
  methods: {
    onOpenModal() {
      if (this.batch.release_type === null) {
        this.batch.release_type = null
      }
      this.fetchBatchs()
      if(this.batchGroup && this.batchGroup.id) {
        this.batch = Object.assign({}, this.batchGroup)
        if(this.batchGroup.release_fixed_date) {
          this.release_fixed_time = this.batchGroup.release_fixed_date.substr(11,9).slice(0, -3)
          this.batch.release_fixed_date = this.batchGroup.release_fixed_date.split(' ')[0];
        }
      }
    },
    fetchBatchs() {
      servicePresentialEvent.read({id: `/offer-groups/${this.$route.params.id_product}`})
        .then(res => {
          this.batch_groups = res
        })
        .catch(err => console.error(err))
    },
    validatorBatch(batch){
      batch.order = this.batch_groups.length + 1
      batch.product_id = this.$route.params.id_product

      if (batch.release_type === null) {
        delete batch.release_fixed_date
        delete batch.release_offer_group_stock_id
        batch.max_paid_sales = null
      }
      if (batch.release_type === 'offer_group_stock') {
        delete batch.release_fixed_date
      }
      if (batch.release_type === 'fixed_date') {
        delete batch.release_offer_group_stock_id
        batch.max_paid_sales = null
      }
      if (batch.release_type === 'by_stock') {
        delete batch.release_fixed_date
        delete batch.release_offer_group_stock_id
      }
      
      if(this.batchGroup){
        batch.id = this.batchGroup.id;
        batch.release_offer_group_stock_id = this.batch.release_offer_group_stock_id;
        batch.tickets = this.batchGroup.tickets;
        batch.available_tickets = this.batchGroup.available_tickets;
        batch.order = this.batchGroup.order;
      }
      return batch
    },
    onSubmit() {
      this.loading = true
      this.$validator.validateAll()
        .then((result) => {
          if (result) {

            if (!this.batchGroup && this.amount < 9) {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 9,00`, {
                title: this.$t("views.cobranca.text_2169"),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              if (document.querySelector("#amount")) {
                document.querySelector("#amount").focus();
              }
              this.loading = false
              return false;
            }

            let batch = Object.assign({}, {...this.batch, release_fixed_time: this.release_fixed_time })
            if(!this.batchGroup) {
              batch = this.validatorBatch(batch)
              if(batch.release_type == 'fixed_date') {
                batch.release_fixed_date = batch.release_fixed_date.substr(0,10) + ' ' + ((batch.release_fixed_time += ':00')  || '')
              }

              servicePresentialEvent
              .create(batch, "/offer-groups")
              .then(() => {
                this.$emit('close')
                this.$bvModal.hide("batch-modal");
                this.$bvToast.toast('Sucesso ao cadastrar o lote', {
                  title: 'Lote',
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true
                })
                this.resetFields()
              })
              .catch(err => {
                console.error(err)
                this.$bvToast.toast('Falha ao tentar cadastrar o lote', {
                  title: 'Lote',
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true
                })
              });
            } else {
              batch = this.validatorBatch(batch);
              if(batch.release_type == 'fixed_date') {
                batch.release_fixed_date = batch.release_fixed_date.substr(0,10) + ' ' + ((batch.release_fixed_time += ':00')  || '')
              }
              delete batch.offer_name
              delete batch.offer_amount

              servicePresentialEvent
              .update(batch, `/offer-groups/${batch.id}`)
              .then(() => {
                this.$emit('close')
                this.$bvModal.hide("batch-modal");
                this.$bvToast.toast('Sucesso ao atualizar o lote', {
                  title: 'Lote',
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true
                })
                this.resetFields()
              })
              .catch(err => {
                console.error(err)
                this.$bvToast.toast('Falha ao tentar atualizar o lote', {
                  title: 'Lote',
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true
                })
              });
            }
            
          } else {
            this.loading = false
          }
        })
        .finally(() => { this.loading = false })
    },
    resetFields() {
      this.$nextTick(()=> {
        this.amount = 0;
        this.batch = {
          name: null,
          offer_name: '',
          offer_amount: 0,
          max_paid_sales: null,
          release_offer_group_stock_id: null,
          release_type: null,
          release_fixed_date: null,
          release_by_stock: null,
          release_no_rules: null
        }
        this.loading = false
        this.batch_groups = []
        this.release_fixed_time = null
        this.money = {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          suffix: "",
          precision: 2,
          masked: false,
        }
      })
    }
  }
}
</script>

<style>
.modal-content .modal-header {
  border-bottom: 1px solid #fff !important;
}
.modal-content .modal-footer {
  border-top: 1px solid #fff !important;
}
.batch-modal-title {
  color: #333333;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 150%;
}
.batch-modal-subtitle {
  color: #81858E;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
.batch-modal-text {
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
#batch-modal___BV_modal_content_ {
  border-radius: 20px !important;
}

.b-time-hours, .b-time-minutes {
  height: auto !important;
}

#release_fixed_date__value_,
#release_fixed_time__value_ {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 48px !important;
  padding-top: 18px !important;
  border-color: transparent !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000 !important;
}
#release_fixed_date,
#release_fixed_time {
  padding-left: 0;
  padding-top: 4px;
}
#release_fixed_date__dialog_ .b-form-date-calendar .b-calendar-inner .b-calendar-grid {
  height: 252px !important;
}
</style>